import styled from "styled-components";

export const Container = styled.div`

`;

export const Wrapper = styled.div`
    &.empty-suitability-questionnaire {
        display: flex;
        align-items: center;
        align-self: center;
    }
`;

export const ContentBody = styled.div`
    &.empty-suitability-questionnaire {
        width: 35px;
        height: 35px;
        padding: 8px 8px 4px 8px;
        border-radius: 50px;
        background-color: ${props => props.theme.grayQuestionPager};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: ${props => props.theme.blueMidnight};

            span.empty-suitability-questionnaire {
                color: ${props => props.theme.white};
            }
        }

        &.selected {
            background-color: ${props => props.theme.blueMidnight};
        }

        @media (max-width: 1023px) {
            width: 18.7px;
            height: 18.7px;
            padding: 4.3px 4.3px 2.1px 4.3px;
            border-radius: 26.7px;
        }
    }
    `;

export const Content = styled.span`
    &.empty-suitability-questionnaire {
        display: block;
        width: min-content;
        height: min-content;
        color: ${props => props.theme.graySilver};
        line-height: 18px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;

        &.selected {
            color: ${props => props.theme.white};
        }

        @media (max-width: 1023px) {
            line-height: 9.6px;
            font-size: 9.6px;
        }
    }
`;

export const Separator = styled.div`
    &.empty-suitability-questionnaire {
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background-color: ${props => props.theme.questionPagerHover};
        margin-left: 4px;
        margin-right: 6px;

        @media (max-width: 1023px) {
            width: 2.7px;
            height: 2.7px;
            border-radius: 26.7px;
            margin-left: 2.1px;
            margin-right: 3.2px;
        }
    }
`;