import React, { useState, useEffect } from 'react';
import { Container, TitleContainer, Card } from './styles';
import SpendingsIcon from 'components/atoms/icons/SpendingsIcon';
import { H4 } from 'components/atoms/Headings/H4';
import MapBody from 'components/molecules/MapBody';
import MapDescription from 'components/molecules/MapDescription';
import { GenericLoading } from 'components/atoms/GenericLoading';

const SpendingsCard = ({
  currentPage,
  onChangePage,
  loading = true,
  value = 0,
  creditCardBillLimit = 0,
}) => {
  const [selected, setSelected] = useState(currentPage === 'spendings');
  const [hovered, setHovered] = useState(currentPage === 'spendings');

  const spending = selected || hovered ? 'selected' : 'spending';
  const selectedClass = selected || hovered ? 'selected' : '';

  const handleClick = () => {
    setSelected(!selected);
    onChangePage('spendings');
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setSelected(currentPage === 'spendings');
    setHovered(currentPage === 'spendings');
  }, [currentPage]);

  if (loading) {
    return (
      <GenericLoading className="budget-card" />
    )
  }

  return (
    <Container
      selected={selected}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={selectedClass}
    >
      <Card>
        <TitleContainer>
          <SpendingsIcon className={selectedClass} />
          <H4 className={spending}>Gastos</H4>
        </TitleContainer>
        <MapBody className={spending} value={value} />
      </Card>
      <MapDescription className={spending} value={creditCardBillLimit} />
    </Container>
  );
};

export default SpendingsCard;
