import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: 25px;
        height: 25px;

        @media (max-width: 1023px){
            width: 13.3px;
            height: 13.3px;
        }
    }
`;

export const Icon = styled.svg`
    &.passive {
        width: 25px;
        height: 25px;
        min-width: 14.4px;
        min-height: 14.4px;
    }

    &.selected-patrimony {
        width: 25px;
        height: 25px;
        min-width: 14.4px;
        min-height: 14.4px;

        path{
            stroke: ${({ theme }) => theme.white};
        }
    }

    &.asset-management {
        width: 25px;
        height: 25px;

        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }

        @media (max-width: 1023px){
            width: 13.3px;
            height: 13.3px;
        }
    }
`;