import styled from 'styled-components';

export const DebtsIconContainer = styled.svg`
  width: 2.45vw;
  height: 1.67vw;

  &.report {
    width: 24px;
    height: 16px;
    min-width: 24px;
    min-height: 16px;
  }

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  @media (max-width: 1080px) {
    width: 26.4px;
    height: 18px;
  }
`;
