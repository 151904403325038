import styled from "styled-components";

export const PencilIconContainer = styled.svg`
  &.th{
    width: 12px;
    height: 14px;
    margin-left: 12px;
    min-width: 10px;
    min-height: 10px;

    path{
      fill: ${({ theme }) => theme.white};
    }
  }
  &.category-title {
    width: 12px;
    height: 14px;
    margin-left: 12px;
    cursor: pointer;
  }

  &.simple-modal {
    width: 19px;
    height: 21px;
    cursor: pointer;

    fill: ${({ theme }) => theme.blueMidnight};

    &.debts {
      fill: ${({ theme }) => theme.redMedium};

      path {
        fill: ${({ theme }) => theme.redMedium};
      }
    }

    &.spendings {
      fill: ${({ theme }) => theme.blueSky};

      path {
        fill: ${({ theme }) => theme.blueSky};
      }
    }

    &.receipt {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};

      path {
        fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      }
    }
    &.patrimony {
      fill: ${({ theme }) => theme.blueMidnight};

      path {
        fill: ${({ theme }) => theme.blueMidnight};
      }
    }
  }

  &.extract-modal {
    width: 19px;
    height: 21px;
    cursor: pointer;
    fill: ${({ theme }) => theme.blueMidnight};

    @media (max-width: 1440px) {
      width: 14px;
      height: 16px;
    }

    @media (max-width: 1024px) {
      width: 10.13px;
      height: 11.2px;
    }
  }

  &.financial-independence {
    width: 12px;
    height: 14px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.white};
    }

    @media (max-width: 1024px) {
      width: 6.4px;
      height: 7.47px;
    }
  }

  &.generic-dream {
    width: 19px;
    height: 21px;
    cursor: pointer;

    @media (max-width: 1024px) {
      width: 10.13px;
      height: 11.2px;
    }

    &.edit {
      path {
        fill: ${({ theme }) => theme.blueMidnight};
      }
    }
  }

  &.extract--category-item {
    width: 12px;
    height: 14px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.grayShadow};
    }

    @media (max-width: 1440px) {
      width: 9px;
      height: 10.5px;
    }
  }

  &.detailed-extract-item {
    width: 12px;
    height: 14px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.grayShadow};
    }

    @media (max-width: 1440px) {
      width: 9px;
      height: 10.5px;
    }
  }
`;