import styled from "styled-components";

export const GrowingUpIconContainer = styled.svg`
    &.goal-panel {
        width: 32px;
        height: 21px;
        min-width: 17.1px;
        min-height: 11.2px;
        
        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }

        &.hovered {
            path {
                fill: ${({ theme }) => theme.white};
            }
        }
    }
`;