import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 26px;
  height: 26px;
  min-width: 14.4px;
  min-height: 14.4px;

  &.gray path {
    stroke: ${props => props.theme.graySilver};
  }

  &.none {
    display: none;
  }
`;
