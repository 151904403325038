import styled from "styled-components";

export const DollarSignIconContainer = styled.svg`
    &.goal-panel {
        width: 31px;
        height: 31px;
        min-width: 16.5px;
        min-height: 16.5px;
        
        path {
            stroke: ${({ theme }) => theme.blueRoyal};
        }

        path:first-child {
            width: 25px;
            height: 25px;
            min-width: 13px;
            height: 13px;
        }

        path:last-child {
            width: 8.33px;
            height: 11.89px;
            min-width: 4px;
            min-height: 6px;
        }
    }
`;