import React from "react";
import {
    UnderConstructionContainer,
    TextContainer
} from './styles';
import UnderConstructionImage from 'assets/page-under-construction.png'
import { IMG } from "components/atoms/IMG";
import { H1 } from "components/atoms/Headings/H1";
import { P } from "components/atoms/P";

export default function PageUnderConstruction() {
    return (
        <UnderConstructionContainer>
            <TextContainer>
                <H1 className="page-under-construction">Página em <br /> construção</H1>
                <P className="page-under-construction first">
                    Este recurso ainda não está disponível. <br />
                    Estamos trabalhando para desenvolver esta <br />
                    ferramenta e aprimorar a sua experiência <br />
                    com os nossos serviços.
                </P>
                <P className="page-under-construction second">Por gentileza, aguarde!</P>
            </TextContainer>
            <IMG src={UnderConstructionImage} className="page-under-construction" alt="Manutenção" />
        </UnderConstructionContainer>
    );
}