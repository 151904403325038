import styled from 'styled-components';

export const ScrollToTopButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 1.04vw;

  &.loading {
    display: none;
  }
`;

export const ScrollToTopButton = styled.div`
  background-color: transparent;
  cursor: pointer;
`;
