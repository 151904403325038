import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: 27px;
        height: 28px;

        @media (max-width: 1023px){
            width: 14.4px;
            height: 14.9px;   
        }
    }
`;

export const Svg = styled.svg`
    &.asset-management {
        width: 27px;
        height: 28px;

        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }

        &.hovered {
            path {
                fill: ${({ theme }) => theme.white};
            }
        }

        @media (max-width: 1023px){
            width: 14.4px;
            height: 14.9px;   
        }
    }
`;