import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 9px;
  height: 15px;
  margin-left: 12px;

  min-width: 10px;
  min-height: 10px;

`;
