import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 36px;
  height: 36px;
  min-width: 14.4px;
  min-height: 14.4px;

  &.selected-patrimony path {
    stroke: ${({ theme }) => theme.white};
  }
`;
