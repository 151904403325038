import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 43px;
  gap: 32px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const ContainerOverlay = styled.div`
  position: relative;
`;
