import React from 'react';
import BudgetManagement from './Budget';
import Map from './Patrimony/Map';
import PlanningAssets from './Patrimony/Planning';
import { useLocation } from 'react-router-dom';

export default function Finances() {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname.includes('budget')) {
    return <BudgetManagement />;
  }
  if (pathname.includes('/finances/patrimony/planning')) {
    return <PlanningAssets />;
  }
  if (pathname.includes('/finances/patrimony/map')) {
    return <Map />;
  }
  return <React.Fragment></React.Fragment>;
}
