import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
`;

export const SideContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: space-between;
  align-self: center;
`

export const TitleCardWrapper = styled.div`
  width: 100%;
  height: min-content;
  display: flex;
  justify-content: space-between;
  gap: 152px;

  &.second-column {
    align-self: center;
  }

  @media (max-width: 1440px) {
    gap: 100px;
  }
`;

export const GroupItens = styled.div`
  &.total {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    gap: 152px;

    @media (max-width: 1440px) {
      gap: 100px;
    }
  }
`;
