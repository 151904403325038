import styled from "styled-components";

export const Container = styled.div`
    &.financial-assets {
        width: 320px;
        height: 320px;
        margin-top: 72px;
        margin-left: 93px;

        @media (max-width: 1023px) {
            width: 171px;
            height: 171px;
            margin-top: 38.4px;
            margin-left: 49.6px;
        }
    }
`;