import React from 'react';
import { Page } from 'components/templates/Page';
import DirecionalButton from 'components/organisms/Direcional';
import { PotDetail } from 'components/organisms/PotDetail';
import { Redirect } from 'react-router-dom';

export default function Detail() {
  const finishedPage = false

  return finishedPage ? (
    <Page.RootContainer>
      <PotDetail />
      <DirecionalButton />
    </Page.RootContainer>
  ) : (
    <Redirect to="/under-construction" />
  )
}
