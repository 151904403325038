import { differenceInCalendarMonths } from 'date-fns';
import { formatDateToISO } from './date';

export const calculateIFP = ({
  end,
  profitability,
  value,
  patrimonialActiveValue,
  financialActiveValue,
}) => {
  const formatEnd = formatDateToISO(end);

  if (
    typeof formatEnd !== 'string' ||
    !formatEnd.match(/^\d{4}-\d{2}-\d{2}$/)
  ) {
    console.error('Data de término inválida:', end);
    return '0';
  }

  const isFirstDayOfMonth = formatEnd.split('-')[2] === '01';
  const subtractValue = isFirstDayOfMonth ? 0 : 1;
  const differenceInMonths =
    differenceInCalendarMonths(new Date(end), new Date()) - subtractValue + 1;
  const n = differenceInMonths <= 0 ? 1 : differenceInMonths;
  const i = profitability / 100;
  const fv = value - patrimonialActiveValue;
  const pv = financialActiveValue;
  const ifp = (fv - pv * (1 + i) ** n) * (((1 + i) ** n - 1) / i) ** -1;

  return ifp || 0;
};

export const getGalleryImages = () => {
  return [
    {
      url:
        'https://images.unsplash.com/photo-1732732938726-f9958f1479f7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732949086-37b89dce8208?q=80&w=2882&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732994563-067328b47e9c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732944453-0e93bd3538b9?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732952333-63d073ed8188?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732957480-9eef3fc9e027?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732961771-408e87655c31?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732941101-eb3297475c50?q=80&w=2982&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732980823-06d0e310963b?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      url:
        'https://images.unsplash.com/photo-1732732976754-ba59f5741388?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  ].map(image => image.url);
};
