import React, { useCallback } from 'react';
import { Page } from 'components/templates/Page';
import { GoalsPanelContainer } from './styles';
import { GoalPanelHeader } from 'components/molecules/GoalPanelHeader';
import { Goal } from 'components/molecules/Goal';
import { GenericLoading } from 'components/atoms/GenericLoading';
import useGoals from 'hooks/useGoals';
import toast from 'react-hot-toast';
import { formatOutputMoney } from 'utils/numbers';

export default function GoalPanel() {
  const {
    goals,
    loading,
    card,
    fetchGoals,
    updateGoal,
    createGoal,
    deleteGoal,
    financialBalance,
    patrimonialBalance,
    fetchBalances,
  } = useGoals();

  const handleToggleDreamStatus = useCallback(
    async (goalId, doneAt) => {
      try {
        const updatedData = { done_at: doneAt ? null : new Date() };
        await updateGoal(goalId, updatedData);
        await fetchGoals();
        toast.success('Sonho realizado!');
      } catch (error) {
        throw error;
      }
    },
    [updateGoal, fetchGoals]
  );
  const handleCreateGoal = async goalData => {
    try {
      const response = await createGoal(goalData);
      await fetchGoals();
      await fetchBalances();
      toast.success('Sonho criado!');
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateGoal = async (goalId, goalData) => {
    try {
      const response = await updateGoal(goalId, goalData);
      await fetchGoals();
      await fetchBalances();
      toast.success('Sonho atualizado!');
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteGoal = async goalId => {
    try {
      await deleteGoal(goalId);
      await fetchGoals();
      toast.success('Sonho deletado!');
    } catch (error) {
      throw error;
    }
  };

  const pageContent = loading ? (
    <Page.RootContainer>
      <GoalPanelHeader loading={loading} />
      <GoalsPanelContainer>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <GenericLoading className="goals-panel-dream" key={index} />
          ))}
      </GoalsPanelContainer>
    </Page.RootContainer>
  ) : (
    <Page.RootContainer>
      <GoalPanelHeader
        financialPatrimony={formatOutputMoney(financialBalance)}
        materialPatrimony={formatOutputMoney(patrimonialBalance)}
        seleable={card.sealed_value}
        investmentValue={card.investments_value}
      />
      <GoalsPanelContainer>
        {goals.map((goal, index) => (
          <Goal
            key={index}
            type={goal.type}
            backgroundImage={goal.backgroundImage}
            dreamProps={goal}
            onToggleDreamStatus={handleToggleDreamStatus}
            reloadPage={fetchGoals}
            onCreateGoal={handleCreateGoal}
            onUpdateGoal={handleUpdateGoal}
            onDeleteGoal={handleDeleteGoal}
          />
        ))}
        <Goal type="empty-goal" onCreateGoal={handleCreateGoal} />
      </GoalsPanelContainer>
    </Page.RootContainer>
  );

  return pageContent;
}
