import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 7px;
  height: 12px;

  &.gray {
    path {
      stroke: ${({ theme }) => theme.grayMedium};
    }
  }
`;
