import styled from 'styled-components';
import Background from '../../../assets/bg-recuperar-senha.png'
import Watermark from '../../../assets/watermark-login.png'

export const WrapperGray = styled.div`
  height: 100%;
  width: 100%;
  background-color: #1E1E1E;
  padding: 16px;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
`;

export const Container = styled.div`
  width: 558px;
  min-width: 558px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .text-container {
    width: 100%;
    max-width: 374px;
  }

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: ${props => props.theme.layout.layout_dark};
    font-weight: 600;
    margin-bottom: 20px;
  }

  h4 {
    color: ${props => props.theme.grafit.hover};
    width: 300px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 5%;
    span {
      font-family: 'Poppins', sans-serif;
      color: ${props => props.theme.gray.custom};
      font-weight: 700;
      margin-left: 4px;
    }
  }

  span {
    display: inline-block;
    height: 44px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    font-family: 'Poppins', sans-serif;
    color: ${props => props.theme.gray.custom};
    margin-bottom: 62px;
  }
`;

export const Logo = styled.img`
  width: 186px;
  height: auto;
  margin-bottom: 16px;
  margin-top: 0;
`;

export const ContainerImage = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1E1E1E url(${Background}) ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 374px;

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .m-8 {
    margin: 8px 0;
  }

  .control-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .control-label, svg {
      transition: all 0.5s ease;
    }

    &:focus-within {
        .control-label {
            color: ${props => props.theme.blue.default};
        }

        svg {
          color: ${props => props.theme.blue.default};
        }

        .bordered {
          border: 1px solid ${props => props.theme.blue.default} !important;
        }
    }
  }

  label {
    font-family: 'Inter', sans-serif;
    margin-bottom: 6px;
    color: ${props => props.theme.gray.custom};
    font-weight: 400;
    line-height: 1.38;
  }

  a {
    font-family: 'Inter', sans-serif;
    color: ${props => props.theme.blue.default};
    font-size: 16px;
    margin-left: 10px;
    font-stretch: 400;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.15px;
  }
`;

export const HeaderGroup = styled.div`
  position: relative;
  display: flex;
  div.watermark {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 52px;
    height: 49px;
    background: url(${Watermark});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  h1 {
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 500;
    margin-top: 100px;
    padding: 0 60px;
  }
`

export const BottomGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  h2 {
    text-align: center;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 500;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
`

export const InputGroup = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  margin-bottom: 16px;
  background: #ffffff;
  border: 1px solid ${props => props.theme.gray.default};
  border-radius: 4px;
  transition: all 0.5s ease;
  width: 100%;
  gap: 16px;

  svg {
    color: ${props => props.theme.gray.default};
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 39px;
    border: 0;
    outline: 0;
    border-radius: 4px;

    &::placeholder {
      font-family: 'Inter', sans-serif;
      color: ${props => props.theme.grafit.default};
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: 0.26px;
    }
  }
`;
