import styled from "styled-components";

export const H1 = styled.h1`
    &.page-under-construction {
        font-size: 26px;
        font-weight: 700;
        font-family: ${({ theme }) => theme.fonts.PoppinsThinItalic.fontFamily};
        color: ${({ theme }) => theme.underConstructionTitle};
        text-transform: uppercase;
    }
`;