import styled from 'styled-components';

export const Tr = styled.tr`
  &.receipt-table-title {
    &.receipts {
      background-color: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }

    &.debts {
      background-color: ${({ theme }) => theme.redBloodWithSlowOpacity};
      opacity: 0.55;
    }

    &.investments {
      background-color: ${({ theme }) => theme.greenDark};
      opacity: 0.55;
    }

    &.spendings {
      background-color: ${({ theme }) => theme.blueSky};
      opacity: 0.55;
    }
  }

  &.receipt-table-body {
    height: 44px;
    border-bottom: 1px solid ${({ theme }) => theme.graySilver};
  }
  &.loading {
    border-bottom: none;
    background-color: transparent;
  }
`;
