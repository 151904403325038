import styled from 'styled-components';

export const IMG = styled.img`
  width: min-content;
  height: min-content;
  src: url(${props => props.src});
  background-color: transparent;
  transition: ${props => props.transition};

  &.gallery-modal {
    display: inline-block;
    width: 111px;
    height: 82px;
    border-radius: 8px;
    cursor: pointer;

    @media (max-width: 1023px) {
      width: 59.2px;
      height: 43.73px;
      border-radius: 4.27px;
    }
  }

  &.filled-suitability-questionnaire {
    display: block;
    width: 510px;
    height: 304px;
    border-radius: 16px;

    @media (max-width: 1023px) {
      width: 272px;
      height: 162.1px;
      border-radius: 8.5px;
    }
  }

  &.page-under-construction {
    width: 45.21vw;
    height: 34.79vw;
    max-width: 868px;
    max-height: 668px;
    min-width: 488px;
    min-height: 376px;
    margin-left: 5.42vw;

    @media (min-width: 1920px) {
      margin-top: 104px;
    }
  }
`;
