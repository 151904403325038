const light = {
  white: '#FFFFFF',
  whiteWithSlowOpacity: '#FFFFFF8C',

  black: '#000000',
  blackWithSlowOpacity: '#00000040',

  background: '#F1F4FF',

  backgroundOverlay: '#96979799',

  pale: '#eaeaea',
  bege: '#aaa',

  scroll: '#f1f1f1',

  scroollHoover: '#888',

  backgroundDream: '#f3f3f3',

  opacityBlueLight: '#697BFF8C',
  opacityBlueSky: '#1403618C',

  opacitySpending: '#697bffd9',

  lilca: '#6e7097',

  hoover: '#e2e2e273',

  grayOpacity: '#969797a6',
  grayLine: '#6C6C6C8C',
  backgroundLilac: '#697BFF26',
  gradeColor: '#697BFF66',

  lilacBackground: '#CDC8EE8C',
  grayGradient: 'rgba(0, 128, 0, 0.1)',
  grayProgress: '#d9d9d9',
  grayDark: '#282728', // gray1
  grayShadow: '#6C6C6C', // gray2
  grayMedium: '#8C8B8C', // gray3
  graySilver: '#969797', // gray4
  grayPale: '#E2E2E2', // gray
  grayLight: '#F7F7F7', // gray6
  grayGrafit: '#b3b3c2',
  grayInputSelect: 'rgba(108, 108, 108, 0.55)',
  graySelectPlaceholder: 'rgba(108, 108, 108, 0.55)',
  grayWebkitScrollbarThumb: '#888',
  webkitScrollbarTrack: '#f1f1f1',

  greenDark: '#0E8769', // green1
  greenMedium: '#43B297', // green2
  greenAqua: '#5CD1B4', // green3
  greenLight: '#66E8C8', // green4
  greenBrave: '#006400',

  red: 'red',
  redDark: '#800022', // red1
  redMedium: '#B1113B', // red2
  redLight: '#D61F4F', // red3
  redPeach: '#DE6E7C', // red4
  redBlood: 'rgba(177, 17, 59, 0.75)',
  redBloodWithSlowOpacity: 'rgba(177, 17, 59, 0.6)',

  pinkLight: '#FFA6C8', // red5

  blueSky: '#697BFF', // blueL1
  blueMedium: '#7386FF', // blueL2
  blueLilac: '#889CFF', // blueL3
  bluePale: '#A3AFFF', // blueL4
  blueLight: '#BBC6FF', // blueL5
  blueSkyWithSlowOpacity: '#697BFF73',

  blueMidnightOpacity: '#0c053f73',
  blueDarkOpacity: '#0c053fa6',

  blueDark: '#060328', // blueD1
  blueMidnight: '#0C053F', // blueD2
  blueRoyal: '#140361', // blueD3
  blueNavy: '#180A83', // blueD4
  blueDeep: '#26239E', // blueD5,
  blueHeaderWithSlowOpacity: 'rgba(24, 10, 131, 0.85)',
  blueRoyalWithSlowOpacity: '#0C053FBF',
  investmentPanelBackground: '#140361D9',

  gradientDarkBlueMenu: ['#0C053F', '#140361', '#26239E'],
  gradientLightBlueButtons: ['#697BFF', '#7386FF', '#889CFF'],
  gradientSkeleton: ['#f0f0f0 ', '#f8f8f8'],
  gradientPhotoDream: ['#0C053F', '#0C053F', '#0C053F'],
  boxShadowColor: ' #0C053F26',
  inputText: '#C5C5C5',
  sideBarHover: '#889CFFA6',
  modalButton: 'rgba(12, 5, 63, 0.75)',
  selectHover: 'rgba(12, 5, 63, 0.55)',
  downIcon: '#D9D9D9',
  grayQuestionPager: '#96979773',
  questionPagerHover: '#0C053F8C',
  suitabilityOptionHover: '#96979733',
  receiptsIconHover: '#180A83A6',
  investmentsIconHover: '#0E876973',
  pinkInstallmentPurchases: '#D61F4FBF',
  tooltipBackground: '#333',
  detailedExtractItemSpending: '#697BFFD9',
  detailedInvestmentItemSpending: '#0E87698C',
  blackScroll: 'rgba(0, 0, 0, 0.3)',
  underConstructionTitle: '#312B5C',

  fonts: {
    PoppinsThin: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 100,
      fontStyle: 'normal',
    },
    PoppinsThinItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 100,
      fontStyle: 'italic',
    },
    PoppinsExtraLight: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 200,
      fontStyle: 'normal',
    },
    PoppinsExtraLightItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 200,
      fontStyle: 'italic',
    },
    PoppinsLight: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
      fontStyle: 'normal',
    },
    PoppinsLightItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
      fontStyle: 'italic',
    },
    PoppinsRegular: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    PoppinsRegularItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontStyle: 'italic',
    },
    PoppinsMedium: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontStyle: 'normal',
    },
    PoppinsMediumItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontStyle: 'italic',
    },
    PoppinsSemiBold: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600,
      fontStyle: 'normal',
    },
    PoppinsSemiBoldItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600,
      fontStyle: 'italic',
    },
    PoppinsBold: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    PoppinsBoldItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 700,
      fontStyle: 'italic',
    },
    PoppinsExtraBold: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 800,
      fontStyle: 'normal',
    },
    PoppinsExtraBoldItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 800,
      fontStyle: 'italic',
    },
    PoppinsBlack: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 900,
      fontStyle: 'normal',
    },
    PoppinsBlackItalic: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 900,
      fontStyle: 'italic',
    },
  },
};

export default light;
