import styled from "styled-components";

export const Container = styled.div`
    &.asset-management-doughnut-grafic-description {
        height: min-content;
    }
`;

export const Svg = styled.svg`
    &.asset-management-doughnut-grafic-description {
        width: 10px;
        height: 5px;
        
        path {
            fill: ${props => props.theme.downIcon};

        }
        
        @media (max-width: 1023px) {
            width: 5.3px;
            height: 2.7px;
        }

        &.liquid-status-positive {
            path {
                fill: ${props => props.theme.greenDark};
            }
        }
    }
`;