import React from 'react';
import { Button, Container, ArrowContainer } from './styles';
import ArrowLeftIcon from 'components/atoms/icons/ArrowLeftIcon';
import ArrowRightIcon from 'components/atoms/icons/ArrowRightIcon';
import { format, addMonths, addYears, isDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const ButtonDate = ({ type, className, currentDate, setCurrentDate, onChangeDate }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const validDate = isDate(currentDate) ? currentDate : new Date();

  const formattedDate = type === 'month'
    ? capitalizeFirstLetter(format(validDate, 'MMMM, yyyy', { locale: ptBR }))
    : format(validDate, 'yyyy', { locale: ptBR });

  const handleNext = () => {
    const date = type === 'month' ? addMonths(validDate, 1) : addYears(validDate, 1)
    setCurrentDate(date);
    onChangeDate(date, type);
  };

  const handlePrevious = () => {
    const date = type === 'month' ? addMonths(validDate, -1) : addYears(validDate, -1)
    setCurrentDate(date);
    onChangeDate(date, type);
  };

  return (
    <Container>
      <Button className={className}>
        <ArrowContainer onClick={handlePrevious}>
          <ArrowLeftIcon className="blue-midnight" />
        </ArrowContainer>
        {formattedDate}
        <ArrowContainer onClick={handleNext}>
          <ArrowRightIcon />
        </ArrowContainer>
      </Button>
    </Container>
  );
};
