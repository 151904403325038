import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 0.83vw;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.67vw;
  width: 19.58vw;
  height: 13.33vw;
  overflow: hidden;
  gap: 0.7vw;
  z-index: 1;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};
  gap: 5px;
  
  &:hover {
    transition: transform 0.3s ease;
    transform: scale(1.05);
    transform-origin: left;
  }

  &.selected {
    background-color: ${({ theme }) => theme.blueSky};
  }

  @media (max-width: 1600px) {
    border-radius: 16px;
    width: 286px;
    height: 213px;
    padding: 20px;
  }

  @media (max-width: 1370px) {
    width: 48%;
  }
`;

export const Card = styled.div`
  width: max-content;
  height: 10.00vw;
  gap: 0.83vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1600px) {
    height: 160px;
    gap: 32px;
  }
`;

export const TitleContainer = styled.div`
  width: max-content;
`;
