import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 671px;
  height: 719px;
  min-width: 500px;
`;

export const FlexContainer = styled.div`
  &.home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`;

export const DivContainer = styled.div`
  &.home {
    display: flex;
    height: 90%;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2%;
  }
`;
export const Line = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grayShadow};
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
  min-width: 400px;
`;
