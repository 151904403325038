import React from 'react';
import MapRetirement from './Map';
import PlanningRetirement from './Planning';
import { useLocation } from 'react-router-dom';

export default function Retirement() {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname.includes('/retirement/map')) {
    return <MapRetirement />;
  }
  if (pathname.includes('/retirement/planning')) {
    return <PlanningRetirement />;
  }
  return <React.Fragment></React.Fragment>;
}
