import React from 'react';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { CommittedMonthlyTable } from './CommittedMonthlyTable';
import { FlexibleMonthlyTable } from './FlexibleMonthlyTable';
import { FlexibleEventualTable } from './FlexibleEventualTable';
import { CommittedEventualTable } from './CommittedEventualTable';

export const SpendingTable = ({
  currentPage,
  committedMonthly,
  flexibleMonthly,
  committedEventual,
  flexibleEventual,
  loading,
  currentDate,
  setCurrentDate,
  updateRecorrenceItem,
  updatePec,
  onChangeDate,
}) => {
  const pages = {
    committedMonthly: (
      <CommittedMonthlyTable
        committedMonthly={committedMonthly}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        updateRecorrenceItem={updateRecorrenceItem}
        onChangeDate={onChangeDate}
      />
    ),
    flexibleEventual: (
      <FlexibleEventualTable
        flexibleEventual={flexibleEventual}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        onChangeDate={onChangeDate}
      />
    ),
    committedEventual: (
      <CommittedEventualTable
        committedEventual={committedEventual}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        updatePec={updatePec}
        onChangeDate={onChangeDate}
      />
    ),
    flexibleMonthly: (
      <FlexibleMonthlyTable
        flexibleMonthly={flexibleMonthly}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        onChangeDate={onChangeDate}
      />),
  };

  if (loading) {
    return <LoadingTable />;
  }
  return pages[currentPage] || <></>;
};
