import styled from "styled-components";

export const GraphSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 32px;
    overflow: auto;
    flex-wrap: wrap;
    margin-top: 34px;
    margin-bottom: 35px;
    
    @media (max-width: 1023px) {
        gap: 17.1px;
        margin-bottom: 18.7px;
        margin-top: 18.1px;
    }

    @media (max-width: 418.1px) {
        justify-content: left;
    }
`;