import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const H5 = styled.h5`
  &.dream-panel {
    align-self: center;
    color: ${({ theme }) => theme.white};
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-weight: 400;
    line-height: 0.9;
    white-space: pre-wrap;
    overflow: hidden;
    font-size: 22px;
    display: flex;
    align-items: center;
    width: min-content;
    max-width: max-content;
  }

  &.map-header {
    color: ${({ theme }) => theme.graySilver};
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily}, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid ${({ theme }) => theme.graySilver};
    }

    @media (max-width: 1440px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1060px) {
      font-size: 0.8rem;
    }
  }

  &.map-border {
    border-bottom: 1px solid ${({ theme }) => theme.graySilver};
  }
  &.map-header-loading {
    width: 100px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    color: transparent;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 50px;

    @media (max-width: 1920px) {
      height: 39px;
    }

    @media (max-width: 1650px) {
      height: 36px;
    }

    @media (max-width: 1500px) {
      height: 33px;
    }

    @media (max-width: 1280px) {
      height: 30px;
    }
  }

  &.font-spending {
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};
    font-size: 16px !important;

    @media (max-width: 1845px) {
      font-size: 12px !important;
    }
  }

  &.font-spending.selected {
    color: ${({ theme }) => theme.white};
  }

  &.selected {
    color: ${({ theme }) => theme.white};
  }
  &.progress-card {
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};
    color: ${({ theme }) => theme.grayMedium};
    font-size: 16px;
    margin-right: 5px;

    @media (max-width: 1023px) {
      font-size: 8.53px;
      line-height: 8.53px;
    }
  }

  &.receipts {
    color: ${({ theme }) => theme.blueNavy};
  }
  &.spending {
    color: ${({ theme }) => theme.blueSky};
  }

  &.investments {
    color: ${({ theme }) => theme.greenDark};
  }

  &.debts {
    color: ${({ theme }) => theme.redMedium};
  }

  &.receipts,
  &.spending,
  &.investments,
  &.debts,
  &.selected {
    font-size: 16px;
    white-space: nowrap;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily}, sans-serif;

    @media (max-width: 1600px) {
      font-size: 12px;
    }
  }

  &.goal-panel {
    color: ${({ theme }) => theme.blueRoyal};
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    margin-top: 13px;

    &.hovered {
      color: ${({ theme }) => theme.white};
    }

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
      margin-top: 6.93px;
    }
  }
  &.legend-table {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: 16px;

    @media (max-width: 1440px){
      font-size: 14px;
    }
  }

  &.spending-card-footer {
    color: ${({ theme }) => theme.blueSky};
    font-size: 16px;
    
    @media (max-width: 1845px) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &.spending-card-footer.selected {
    color: ${({ theme }) => theme.white};
  }  
`;
