import styled from 'styled-components';

export const Button = styled.button`
  &.simple-modal {
    font-size: 0.9rem;
    border-radius: 4px;
    padding: 0 8px;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.receipt {
      &.cancel {
        background-color: ${props => props.theme.blueHeaderWithSlowOpacity};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &.debts, &.delete {
      &.cancel {
        background-color: ${props => props.theme.redMedium};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &.edit {
      &.cancel {
        background-color: ${props => props.theme.modalButton};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &.spendings {
      &.cancel {
        background-color: ${props => props.theme.blueSky};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &:focus {
      outline: 0;
    }
  }

  &.calendar-button {
    background-color: ${props => props.theme.blueRoyal};
    padding: 4px 10px;
    border-radius: 14px;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 0.4rem;
    line-height: 0.4rem;
    font-weight: 400;
    border: none;
    color: ${props => props.theme.whiteWithSlowOpacity};
    height: min-content;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    @media (max-width: 1440px) {
      font-size: 0.3rem;
      line-height: 0.3rem;
      padding: 3px 8px;
      border-radius: 10px;
    }
  }

  &.generic-dream-modal {
    width: 163px;
    min-width: 100px;
    background-color: ${props => props.theme.blueRoyalWithSlowOpacity};
    padding: 5px;
    border-radius: 4px;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 18px;
    font-weight: 400;
    border: none;
    color: ${props => props.theme.white};
    height: min-content;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.5;
    }

    @media (max-width: 1023px) {
      padding: 5.87px 8.27px;
      border-radius: 2.13px;
      font-size: 10.67px;
      line-height: 10.67px;
    }
  }

  &.empty-suitability-questionnaire,
  &.filled-suitability-questionnaire {
    height: 50px;
    border-radius: 8px;
    background-color: ${props => props.theme.blueRoyal};
    color: ${props => props.theme.white};
    align-self: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 45px;
    padding: 16px;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.5;
    }

    &.suitability-form {
      margin-left: 32px;
    }

    @media (max-width: 1023px) {
      height: 26.7px;
      border-radius: 4.3px;
      font-size: 9.6px;
      margin-top: 17.1px;
      margin-bottom: 24px;
      line-height: 9.6px;
      padding: 8.5px;

      &.suitability-form {
        margin-left: 17.1px;
      }
    }
  }
  &.retirement-table {
    width: 280px;
    height: 68px;
    font-size: 20px;
    background-color: ${props => props.theme.grayOpacity};
    color: ${props => props.theme.white};
    border: none;
    border-radius: 6px;

    @media (max-width: 1023px) {
      height: 26.7px;
      border-radius: 4.3px;
      font-size: 9.6px;
      margin-top: 17.1px;
      margin-bottom: 24px;
      line-height: 9.6px;
      padding: 8.5px;
    }
  }
  &.grafic-table {
    width: 60px;
    height: 24px;
    font-size: 14px;
    min-width: 50px;
    background-color: ${props => props.theme.blueRoyal};
    color: ${props => props.theme.white};
    border: none;
    border-radius: 4px;

    &:focus {
      outline: 0;
    }
  }
  &.report-spending {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.blueSky};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.blueSky};
    }

    &:hover {
      background-color: ${props => props.theme.blueSky};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-receipt {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.blueNavy};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.blueNavy};
    }

    &:hover {
      background-color: ${props => props.theme.blueNavy};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-investment {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.greenDark};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.greenDark};
    }

    &:hover {
      background-color: ${props => props.theme.greenDark};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-debts {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.redMedium};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.redMedium};
    }

    &:hover {
      background-color: ${props => props.theme.redMedium};
    }
    &:focus {
      outline: 0;
    }
  }

  &.extract-modal {
    font-size: 0.75rem;
    border-radius: 4px;
    padding: 0 8px;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: 0;
    }
    @media (max-width: 1024px) {
      font-size: 0.6rem;
    }
  }

  &.extract-modal.cancel {
    background-color: ${props => props.theme.modalButton};
    color: ${props => props.theme.white};
    border: none;
    &:hover {
      opacity: 0.5;
    }
  }
  
  &.extract-modal.confirm {
    background-color: ${props => props.theme.redMedium};
    color: ${props => props.theme.white};
    border: none;
    &:hover {
      opacity: 0.5;
    }
  }
`;
