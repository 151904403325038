import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: max-content;
    background-color: ${props => props.theme.white};
    margin-top: 34px;
    margin-bottom: 43px;
    padding-top: 45px;
    padding-left: 43px;
    padding-bottom: 45px;
    border-radius: 16px;

    @media (max-width: 1023px) {
        margin-top: 18.1px;
        margin-bottom: 22.9px;
        padding-top: 24px;
        padding-left: 22.9px;
        padding-bottom: 24px;
        border-radius: 8.5px;
    }
`;

export const GraphContainer = styled.div`
    margin-top: 32px;
    display: flex;
    gap: 32px;
    overflow: auto;

    @media (max-width: 1023px) {
        margin-top: 17.1px;
        gap: 17.1px;
    }
`;