import styled from "styled-components";

export const Container = styled.div`
    &.immediate-liquidity {
        width: 1522px;
        height: 677px;

        @media (max-width: 1023px) {
            width: 811.7px;
            height: 361.1px;
        }
    }
`;