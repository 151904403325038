import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;
  position: relative;
  width: 1600px;
`;

export const CarouselContent = styled.div`
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

export const CardContainer = styled.div`
  display: inline-flex;
  margin-right: 8px;
`;
