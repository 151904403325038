import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/organisms/Sidebar';
import styled from 'styled-components';
import { Header } from 'components/organisms/Header';
import { useLocation } from 'react-router-dom';
import { generateClassName } from 'utils/text';

const MainContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.background};
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  height: 100%;
  overflow: auto;
  transform-origin: top left;
  background-color: ${({ theme }) => theme.background};

  &.home {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    overflow: hidden;
    transform: translateX(88px) scale(0.85);


    @media (min-width: 1920px) and (min-height: 3000px) {
      transform: translateX(88px) scale(1.2);
    }

    @media (min-width: 1920px) and (min-height: 2500px) {
      transform: translateX(88px) scale(1.1);
    }

    @media (min-width: 1920px) and (min-height: 2000px) {
      transform: translateX(88px) scale(1.05);
    }

    @media (min-width: 1920px) and (min-height: 1500px) {
      transform: translateX(88px) scale(1);
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
      transform: translateX(88px) scale(0.9);
    }

    @media (max-width: 1920px), (max-height: 1079px) {
      transform: translateX(5%) scale(0.85);
    }

    @media (max-height: 768px) {
      transform: translateX(88px) scale(0.75);
    }

    @media (max-height: 686px) {
      transform: translateX(88px) scale(0.65);
    }

    @media (max-height: 537px) {
      transform: translateX(88px) scale(0.55);
    }

    @media (max-height: 400px) {
      transform: translateX(88px) scale(0.5);
    }
  }

  &.full-width {
    width: 100%;
  }
`;

export default function DashboardLayout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const fullPages = useMemo(() => ['/under-construction'], []);

  const isHome = useMemo(() => location.pathname === '/home', [location.pathname]);
  const isFullWidth = useMemo(() => fullPages.includes(location.pathname), [location.pathname, fullPages]);

  const containerClassname = generateClassName({
    'full-width': isFullWidth,
    home: isHome,
  });

  return (
    <MainContainer>
      <Sidebar isOpen={isMenuOpen} handleOpen={setIsMenuOpen} />
      <Container className={containerClassname}>
        <Header />
        {children}
      </Container>
    </MainContainer>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
