import styled from "styled-components";

export const RootContainer = styled.div`
    &.goal-panel,
    &.asset-management,
    &.asset-management-liquidity {
        width: 376px;
        height: 256px;
        background-color: ${({ theme }) => theme.white};
        border-radius: 16px;
        box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.blackWithSlowOpacity};
        padding-top: 32px;
        padding-left: 32px;
        min-width: 200.53px;
        min-height: 136.53px;

        &.first {
            padding-right: 27px;

            @media (max-width: 1024px){
                padding-right: 14px;
            }
        }

        &.second {
            padding-right: 19px;

            @media (max-width: 1024px){
                padding-right: 10.133px;
            }
        }

        &.third {
            padding-right: 39px;

            @media (max-width: 1024px){
                padding-right: 20.8px;
            }
        }

        &.sealed {
            background-color: transparent;
            box-shadow: none;
            border: 1px solid ${({ theme }) => theme.blueRoyal};

            @media (max-width: 1023px){
                border: 0.54px solid ${({ theme }) => theme.blueRoyal};
            }
        }

        @media (max-width: 1023px) {
            width: 200.53px;
            height: 136.53px;
            padding-top: 17.067px;
            padding-left: 17.067px;
            border-radius: 8.54px;
            box-shadow: 2.1px 2.1px 8px 0px ${({ theme }) => theme.blackWithSlowOpacity};
        }
    }

    &.asset-management {
        &.hovered {
            transition: transform 0.3s ease-in-out;
            transform-origin: left end;
            background-color: ${({ theme }) => theme.blueRoyal};
            
            &:hover {
                transform: scale(1.05) translateX(10px) translateY(10px);
            }
        }
    }
`;

export const GroupItens = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.goal-panel {
        height: 36px;
    }

    &.asset-management {
        width: 100%;
        height: 108px;
        justify-content: left;
        gap: 44px;
        margin-top: 20px;

        @media (max-width: 1023px) {
            height: 57.6px;
            gap: 23.5px;
            margin-top: 10.7px;
        }
    }

    &.asset-management-container {
        height: 108px;
        flex-direction: column;

        @media (max-width: 1023px) {
            height: 57.6px;
        }
    }

    &.asset-management-container-liquity {
        width: 62px;
        padding: 1px 2.5px;

        @media (max-width: 1023px) {
            width: 33.1px;
            padding: 0.5px 1.3px;
        }      
    }
`;

export const AmountOfMoneyContainer = styled.div`
    &.goal-panel {
        margin-top: 40px;

        @media (max-width: 1024px) {
            margin-top: 17px;
        }
    }
`;