import styled from "styled-components";

export const HouseIconContainer = styled.svg`
    &.goal-panel {
        width: 27px;
        height: 27px;
        min-width: 14.4px;
        min-height: 14.4px;
        
        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }
    }
`;