import React from 'react';
import { StyledContainer } from './styles';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import ReactTooltip from 'react-tooltip';

const OptionOverlay = ({
  onClick,
  className,
  tooltipColor = 'rgba(0, 0, 0, 0.8)',
}) => {
  return (
    <StyledContainer onClick={onClick}>
      <PencilIcon
        className="category-title"
        data-tip="Editar item"
      />
      <ReactTooltip
        place="right"
        backgroundColor={tooltipColor}
        effect="solid"
        delayShow={200}
      />
    </StyledContainer>
  );
};

export default OptionOverlay;
