import React, { useState } from 'react';
import { DreamCard } from 'components/templates/DreamCard';
import { GenericDreamModal } from 'components/molecules/GenericDreamModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import { formatOutputMoney } from 'utils/numbers';

export const GenericDream = ({
  dreamProps,
  onToggleDreamStatus,
  onCreateGoal = () => {},
  onUpdateGoal = () => {},
  reloadPage,
  onDeleteGoal = () => {},
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleModal = () => setShowModal(prev => !prev);
  const toggleDeleteModal = () => setShowDeleteModal(prev => !prev);

  const handleDelete = () => {
    onDeleteGoal(dreamProps?.id);
    toggleDeleteModal();
  };

  const dreamBody = {
    realized: (
      <>
        <DreamCard.RootContainer
          className="goal-panel realized-generic-dream"
          backgroundImage={dreamProps?.url}
        >
          <DreamCard.Icon
            icon="round-verified"
            className="realized-generic-dream"
          />
          <DreamCard.Text
            text={dreamProps?.name}
            className="goals-panel generic-dream realized"
          />
          <DreamCard.GroupItens className="on-center">
            <DreamCard.Icon
              icon="trash"
              className="delete-generic-dream"
              onClick={toggleDeleteModal}
            />
            <DreamCard.Icon
              icon="pencil"
              className="financial-independence"
              onClick={toggleModal}
            />
            <DreamCard.Icon
              icon="verified"
              className="realized-dream"
              onClick={() =>
                onToggleDreamStatus(dreamProps.id, dreamProps.done_at)
              }
            />
          </DreamCard.GroupItens>
        </DreamCard.RootContainer>
      </>
    ),
    unrealized: (
      <DreamCard.RootContainer
        className="goal-panel generic-dream"
        backgroundImage={dreamProps?.url}
      >
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text={dreamProps.name}
            className="goals-panel generic-dream title"
          />
          <DreamCard.GroupItens>
            <DreamCard.Icon
              icon="trash"
              className="delete-generic-dream"
              onClick={toggleDeleteModal}
            />
            <DreamCard.Icon
              icon="pencil"
              className="financial-independence"
              onClick={toggleModal}
            />
            <DreamCard.Icon
              icon="verified"
              className="financial-independence"
              onClick={() =>
                onToggleDreamStatus(dreamProps.id, dreamProps.done_at)
              }
            />
          </DreamCard.GroupItens>
        </DreamCard.GroupItens>
        <DreamCard.Text
          text={dreamProps.formatted_when}
          className="goals-panel generic-dream mim"
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text="M.I.M"
            className="goals-panel generic-dream mim"
          />
          <DreamCard.Text
            text={formatOutputMoney(dreamProps.value)}
            className="goals-panel generic-dream mim"
          />
        </DreamCard.GroupItens>

        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text={formatOutputMoney(dreamProps.saved_money)}
            className="goals-panel generic-dream progress-information"
          />
          <DreamCard.Text
            text={formatOutputMoney(dreamProps.value)}
            className="goals-panel generic-dream progress-information"
          />
        </DreamCard.GroupItens>
        <DreamCard.ProgressBar
          className="goals-panel dream-panel"
          progress={dreamProps.percentage_completed}
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text="Total investido"
            className="goals-panel generic-dream progress-information percentage"
          />
          <DreamCard.Text
            text={`${dreamProps.percentage_completed}%`}
            className="goals-panel generic-dream progress-information percentage"
          />
        </DreamCard.GroupItens>
      </DreamCard.RootContainer>
    ),
  };

  return (
    <>
      {dreamBody[dreamProps.done_at ? 'realized' : 'unrealized']}
      {showModal && (
        <GenericDreamModal
          showModal={showModal}
          setShowModal={setShowModal}
          dreamProps={dreamProps}
          action="edit"
          onCreateGoal={onCreateGoal}
          onUpdateGoal={onUpdateGoal}
          onConfirm={reloadPage}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title="Excluir sonho"
          text="Excluir sonho?"
          highlightedText="Esta ação é permanente e não poderá ser desfeita"
          onConfirm={handleDelete}
          cancelButtonText="Cancelar"
          confirmButtonText="Excluir sonho"
          theme="debts"
        />
      )}
    </>
  );
};
