import React from 'react';
import { Modal } from 'components/templates/Modal';
import { getGalleryImages } from 'utils/goals';

export const GalleryModal = ({
  setShowModal = () => {},
  showModal = false,
  getImageValue,
}) => {
  const images = getGalleryImages();

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="gallery-modal"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Adicionar sonho"
          onClose={() => setShowModal(false)}
          type="add"
          className="generic-dream add"
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer className="gallery-modal">
        <Modal.GalleryImage
          className="gallery-modal"
          images={images}
          getImageValue={getImageValue}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </Modal.BodyContainer>
    </Modal.Container>
  );
};
