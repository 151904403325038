import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 40px;
  height: 32px;

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  @media (max-width: 1845px) {
    width: 29px;
    height: 24px;
  }
`;
