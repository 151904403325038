import styled from 'styled-components';

export const ModalBody = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin: 38px 0;

  &.modal {
    display: flex;
  }

  &.generic-dream {
    margin: 0;
    display: flex;
    gap: 48px;
  }

  &.gallery-modal {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    width: 349px;
    height: 172px;
    overflow: auto;
    margin: 18px 0 0 0;
  }

  &.extract-modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;
