import { Table } from 'components/atoms/Table';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import React from 'react';
import { TitleLoading } from 'components/molecules/TableTitleLoading';
import { CardContainer, Container, GroupIcons } from './styles';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const LoadingTable = () => {
  return (
    <>
      <CardContainer>
        <TitleLoading />
        <Container className='loading'>
          <Table className="receipt-table loading">
            <thead>
              <Tr className="receipt-table-title receipts loading">
                <Th className="loading" />
              </Tr>
            </thead>
            <tbody>
              <Tr className="loading" />
            </tbody>
          </Table>
        </Container>
        <GroupIcons>
          <GenericLoading className='simple-icon-loading' />
          <GenericLoading className='simple-icon-loading' />
        </GroupIcons>
      </CardContainer>
    </>
  );
};
