import styled from 'styled-components';

export const Table = styled.table`
  &.receipt-table {
    width: 100%;
    height: min-content;
    overflow: auto;
    border-collapse: collapse;
    margin-top: 23px;

    @media (max-width: 1280px) {
      width: 960px;
    }
  }

  &.receipt-table.loading {
    width: 100%;
  }  
`;
