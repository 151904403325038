import styled from "styled-components";

export const UnderConstructionContainer = styled.div`
    height: 100vh;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: center;
    justify-content: center;
`;

export const TextContainer = styled.div`
    width: max-content;
    margin-left: 5vw;
`;