export const getAllocationStrategy = () => {
  return [
    { label: 'R. Fixa Pós', value: 'R. Fixa Pós' },
    { label: 'R. Fixa Pré', value: 'R. Fixa Pré' },
    { label: 'R. Fixa IPCA', value: 'R. Fixa IPCA' },
    { label: 'Multimercado', value: 'Multimercado' },
    { label: 'FIIs', value: 'FIIs' },
    { label: 'Ações BR', value: 'Ações BR' },
    { label: 'R.V. Exterior', value: 'R.V. Exterior' },
    { label: 'Alternativo', value: 'Alternativo' },
    { label: 'ELP', value: 'ELP' },
  ];
};

export const getLiquidity = () => {
  return [
    { label: 'DO', value: 'DO' },
    { label: 'Até 30 dias', value: 'Até 30 dias' },
    { label: 'De 1 à 6 meses', value: 'De 1 à 6 meses' },
    { label: 'De 6 à 12 meses', value: 'De 6 à 12 meses' },
    { label: 'De 12 à 24 meses', value: 'De 12 à 24 meses' },
    { label: 'Acima de 24 meses', value: 'Acima de 24 meses' },
  ];
};

export const getAllocationStrategyBPL = () => {
  return [
    { label: 'R. Fixa Pós', value: 'R. Fixa Pós' },
    { label: 'R. Fixa Pré', value: 'R. Fixa Pré' },
    { label: 'R. Fixa IPCA', value: 'R. Fixa IPCA' },
    { label: 'Multimercado', value: 'Multimercado' },
    { label: 'FIIs', value: 'FIIs' },
    { label: 'Ações BR', value: 'Ações BR' },
    { label: 'R.V. Exterior', value: 'R.V. Exterior' },
    { label: 'Alternativo', value: 'Alternativo' },
    { label: 'ELP', value: 'ELP' },
  ];
};

export const getAllocationStrategyPLF = () => {
  return [
    { label: 'R. Fixa Pós', value: 'R. Fixa Pós' },
    { label: 'R. Fixa Pré', value: 'R. Fixa Pré' },
    { label: 'R. Fixa IPCA', value: 'R. Fixa IPCA' },
    { label: 'Multimercado', value: 'Multimercado' },
    { label: 'FIIs', value: 'FIIs' },
    { label: 'Ações BR', value: 'Ações BR' },
    { label: 'R.V. Exterior', value: 'R.V. Exterior' },
    { label: 'Alternativo', value: 'Alternativo' },
    { label: 'ELP', value: 'ELP' },
  ];
};
