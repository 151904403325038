import styled from 'styled-components';

export const LegendCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 537px;
  margin-top: 48px;
  height: auto;
  min-width: 350px;
`;
