import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 29px;
  height: 32px;

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  @media (max-width: 1845px) {
   width: 21px;
   height: 24px;
  }
`;
