export function formatInputMoney(value) {
  let formattedValue = getOnlyNumbers(value);
  return formatOutputMoney(formattedValue / 100);
}

export const formatOutputMoney = value => {
  return Number(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export function getOnlyNumbers(value) {
  return Number(String(value).replace(/[^\d]/g, ''));
}

export function getAnualValue(value) {
  let formattedValue = getOnlyNumbers(value);
  formattedValue = formattedValue * 12;
  formattedValue = formatInputMoney(formattedValue);
  return formattedValue;
}

export const getMonthValue = value => {
  let formattedValue = getOnlyNumbers(value);
  formattedValue = formattedValue / 12;
  formattedValue = formatInputMoney(formattedValue);
  return formattedValue;
};

export const getArrayNumbers = (quantity, firstIsZero = true) => {
  let array = [];
  for (let i = 0; i < quantity; i++) {
    array.push(firstIsZero ? i : i + 1);
  }
  return array;
};
export const formatOutputMoneyNoDecimals = value => {
  return Number(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
export const formatCurrency = (value, currency, precision) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL' || currency,
    maximumFractionDigits: 0 || precision,
    minimumFractionDigits: 0,
  });

  return formatter
    .format(value || 0)
    .replace(currency || 'R$', currency ? currency + ' ' : 'R$ ');
};

export const parseCurrency = value => {
  const cleanValue = value.replace(/R\$|\s/g, '');
  const floatValue = parseFloat(cleanValue.replace('.', '').replace(',', '.'));
  return floatValue;
};
export const formatPercentage = value => {
  const percentageSymbol = '% a.m';
  const numbersOnly = String(getOnlyNumbers(value));
  if (numbersOnly.length > 2) {
    const integerPart = numbersOnly.slice(0, numbersOnly.length - 2);
    const decimalPart = numbersOnly.slice(numbersOnly.length - 2);
    return `${integerPart},${decimalPart}${percentageSymbol}`;
  } else if (numbersOnly.length > 0) {
    return `0,${numbersOnly.padStart(2, '0')}${percentageSymbol}`;
  } else {
    return `0,00${percentageSymbol}`;
  }
};
