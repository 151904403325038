import styled from 'styled-components';

export const InvestmentIconContainer = styled.svg`
  width: 2.14vw;
  height: 1.41vw;

  &.report {
    width: 23px;
    height: 15px;
    min-width: 23px;
    min-height: 15px;
  }

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  &.extract.investments {
    width: 23px;
    height: 15px;
    path {
      fill: ${({ theme }) => theme.greenDark};
    }
    &.selected path {
      fill: ${({ theme }) => theme.white};
    }
    @media (max-width: 1440px){
      width: 20px;
      height: 13px;
    }
  }

  @media (max-width: 1080px) {
    width: 23.1px;
    height: 15.2px;
  }
`;
