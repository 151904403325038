import React, { useEffect, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { getAllocationStrategy, getLiquidity } from 'utils/dreams';
import { GalleryModal } from 'components/organisms/GalleryModal';
import { formatDateToDisplay, formatDateToISO } from 'utils/date';
import { calculateIFP } from 'utils/goals';
import toast from 'react-hot-toast';
import { genericDreamSchema } from 'utils/validator';

export const GenericDreamModal = ({
  showModal = '',
  setShowModal = () => {},
  onConfirm = () => {},
  action = '',
  dreamProps,
  onCreateGoal = () => {},
  onUpdateGoal = () => {},
}) => {
  const [name, setName] = useState(dreamProps?.name || '');
  const [description, setDescription] = useState(dreamProps?.description || '');
  const [imageDream, setImageDream] = useState(dreamProps?.url || '');
  const [noDefinedValue, setNoDefinedValue] = useState(
    dreamProps?.noDefinedValue || false
  );
  const [goalValue, setGoalValue] = useState(dreamProps?.value || '');
  const [deadline, setDeadline] = useState(
    formatDateToISO(dreamProps?.end) || ''
  );
  const [allocationStrategy, setAllocationStrategy] = useState(
    dreamProps?.strategic_allocation || ''
  );
  const [liquidity, setLiquidity] = useState(dreamProps?.liquidity || '');
  const [currentFixedAssets, setCurrentFixedAssets] = useState(
    dreamProps?.patrimonial_active_value || ''
  );
  const [currentFinancialAssets, setCurrentFinancialAssets] = useState(
    dreamProps?.financial_active_value || ''
  );
  const [profitability, setProfitability] = useState(
    dreamProps?.profitability || ''
  );
  const [savedMoney, setSavedMoney] = useState(dreamProps?.saved_money || '');
  const [showGaleryModal, setShowGaleryModal] = useState(false);
  const [ifpValue, setIfpValue] = useState(0);

  const formattedProfitability =
    typeof profitability === 'string'
      ? parseFloat(profitability.replace('%', '').replace(',', '.'))
      : profitability || 0;

  useEffect(() => {
    const formattedProfitability =
      typeof profitability === 'string'
        ? parseFloat(profitability.replace('%', '').replace(',', '.'))
        : profitability || 0;

    const fixedAssets = parseFloat(
      (currentFixedAssets || '0').toString().replace(',', '.')
    );
    const financialAssets = parseFloat(
      (currentFinancialAssets || '0').toString().replace(',', '.')
    );

    const calculatedIFP = calculateIFP({
      end: deadline,
      profitability: formattedProfitability,
      value: goalValue,
      patrimonialActiveValue: fixedAssets,
      financialActiveValue: financialAssets,
    });

    setIfpValue(calculatedIFP);
    setSavedMoney(fixedAssets + financialAssets);
  }, [
    deadline,
    profitability,
    goalValue,
    currentFixedAssets,
    currentFinancialAssets,
  ]);

  const validateFields = () => {
    let errors = {};

    try {
      genericDreamSchema.validateSync({
        name,
        description,
        deadline,
        imageDream,
      });
    } catch (error) {
      errors = { ...errors, [error.path]: error.message };
    }

    return errors;
  };

  const handleSave = async () => {
    const errors = validateFields();

    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => {
        toast.error(error);
      });
      return;
    }

    const goalData = {
      name,
      description,
      value: goalValue,
      end: deadline,
      profitability: formattedProfitability,
      saved_money: parseFloat((savedMoney || '0').toString().replace(',', '.')),
      strategic_allocation: allocationStrategy,
      strategic_allocation_bpl: dreamProps?.strategic_allocation_bpl || '',
      patrimonial_active_value: currentFixedAssets,
      financial_active_value: currentFinancialAssets,
      financial_active_value_bpl: dreamProps?.financial_active_value_bpl || '',
      liquidity,
      liquidity_bpl: dreamProps?.liquidity_bpl || '',
      bpl: dreamProps?.bpl || '',
      plf: dreamProps?.plf || '',
      type: dreamProps?.type || '',
      external_url: imageDream,
    };

    try {
      if (action === 'edit') {
        const response = await onUpdateGoal(dreamProps?.id, goalData);
        onConfirm(response);
      } else {
        const response = await onCreateGoal(goalData);
        onConfirm(response);
      }
      setShowModal(false);
    } catch (error) {
      console.error(
        `Failed to ${action === 'edit' ? 'update' : 'create'} goal:`,
        error
      );
    }
  };

  const modalHeader = {
    edit: (
      <Modal.SimpleTitleContent
        title="Editar sonho"
        onClose={() => setShowModal(false)}
        type="edit"
        className="generic-dream edit"
      />
    ),
    add: (
      <Modal.SimpleTitleContent
        title="Adicionar sonho"
        onClose={() => setShowModal(false)}
        type="add"
        className="generic-dream add"
      />
    ),
  };

  return (
    <>
      {showGaleryModal ? (
        <GalleryModal
          showModal={showGaleryModal}
          setShowModal={setShowGaleryModal}
          getImageValue={setImageDream}
        />
      ) : (
        <Modal.Container
          showModal={showModal}
          setShowModal={setShowModal}
          className="generic-dream"
          extended={!noDefinedValue}
        >
          <Modal.TitleContainer>{modalHeader[action]}</Modal.TitleContainer>
          <Modal.BodyContainer className="generic-dream">
            <Modal.Section>
              <Modal.InputText
                label="Nome do sonho"
                getInputValue={setName}
                className="generic-dream-modal first"
                defaultValue={name}
              />
              <Modal.InputText
                label="Descrição"
                getInputValue={setDescription}
                className="generic-dream-modal after"
                defaultValue={description}
              />
              <Modal.InputImage
                className="generic-dream"
                backgroundImage={imageDream}
                getImageValue={setImageDream}
                onClick={() => setShowGaleryModal(true)}
              />
              <Modal.GroupInputs show={true} className="not-separate">
                <Modal.DatePicker
                  className="generic-dream-modal-datepicker after"
                  label="Prazo"
                  getInputValue={setDeadline}
                  placeholder={formatDateToDisplay(deadline)}
                  defaultValue={deadline}
                />
                <Modal.Switch
                  label="Sem valor definido"
                  className="generic-dream-modal after term"
                  getSwitchValue={setNoDefinedValue}
                  defaultValue={noDefinedValue}
                />
              </Modal.GroupInputs>
              {!noDefinedValue && (
                <Modal.InputMoney
                  label="Valor total"
                  getInputValue={setGoalValue}
                  className="generic-dream-modal after"
                  defaultValue={dreamProps?.formatted_value}
                />
              )}
            </Modal.Section>
            {!noDefinedValue && (
              <Modal.Section className="separate">
                <Modal.GroupInputs show={true} className="generic-dream-modal">
                  <Modal.InputMoney
                    label="Patrimônio Imobilizado atual"
                    getInputValue={setCurrentFixedAssets}
                    className="generic-dream-modal first half second-section"
                    defaultValue={currentFixedAssets}
                  />
                  <Modal.InputMoney
                    label="Patrimônio Financeiro atual"
                    getInputValue={setCurrentFinancialAssets}
                    className="generic-dream-modal first half last second-section"
                    defaultValue={currentFinancialAssets}
                  />
                </Modal.GroupInputs>
                <Modal.InputMoney
                  label="Valor total atual"
                  getInputValue={() => {}}
                  className="generic-dream-modal after second-section"
                  defaultValue={savedMoney}
                  disabled
                />
                <Modal.GroupInputs show={true} className="generic-dream-modal">
                  <Modal.Dropdown
                    label="Estratégia de alocação"
                    className="generic-dream-modal after half second-section"
                    selectOptions={getAllocationStrategy()}
                    getDropdownValue={setAllocationStrategy}
                    placeholderSelect={allocationStrategy}
                  />
                  <Modal.Dropdown
                    label="Liquidez"
                    className="generic-dream-modal after half last second-section"
                    selectOptions={getLiquidity()}
                    getDropdownValue={setLiquidity}
                    placeholderSelect={liquidity}
                  />
                </Modal.GroupInputs>
                <Modal.InputPercentage
                  label="Rentabilidade"
                  getInputValue={value => setProfitability(value)}
                  className="generic-dream-modal after second-section"
                  defaultValue={profitability}
                />
                <Modal.InputMoney
                  label="Investimento mensal"
                  getInputValue={() => {}}
                  className="generic-dream-modal after second-section"
                  defaultValue={ifpValue}
                  disabled
                />
              </Modal.Section>
            )}
          </Modal.BodyContainer>
          <Modal.FooterWithOneButton
            text="Confirmar"
            className="generic-dream-modal"
            onClick={handleSave}
          />
        </Modal.Container>
      )}
    </>
  );
};
