import CheckedIcon from '../icons/CheckedIcon';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from './styles';
import React from 'react';

const Checkbox = ({ className, checked, onClick, onChange }) => {
  return (
    <CheckboxContainer className={className} onClick={onClick} onChange={onChange}>
      <HiddenCheckbox checked={checked} className={className} onChange={() => { }} />
      <StyledCheckbox checked={checked} className={className} onChange={() => { }}>
        <CheckedIcon className={className} />
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
