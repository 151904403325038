import styled from 'styled-components';

export const ChartWrapper = styled.div`
  padding-left: 10px;
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;
export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const Title = styled.h2`
  color: ${props => props.theme.blueRoyal};
  font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
  font-size: 14px;
  margin-bottom: 10px;

  &.year {
    margin-left: 5px;
    margin-bottom: 25px;
  }
`;

export const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StyledCanvas = styled.canvas`
  width: auto;
  height: auto;
`;
