import React from 'react';
import { Container, TitleCardWrapper, GroupItens, SideContainer } from './styles';
import { Span } from 'components/atoms/Span';
import GoIcon from 'components/atoms/icons/GoIcon';
import { Pencil } from 'components/atoms/icons/Pencil';

export const BudgetTableTitle = ({
  setShowModal,
  nameTable,
  total,
  percentage,
  showEditIcon = true,
  showGoldIcon = false,
  className,
  categoryId,
  categoryClassification,
  setSelectedCategory
}) => {
  return (
    <Container>
      <SideContainer>
        <GroupItens>
          <Span className="category-title">{nameTable}</Span>
          {showEditIcon && (
            <Pencil
              className="category-title"
              onClick={() => {
                setSelectedCategory({
                  categoryId,
                  name: nameTable,
                  classification: categoryClassification,
                })
                setShowModal(prev => !prev);
              }}
            />
          )}
          {showGoldIcon && <GoIcon className="budget-table" />}
        </GroupItens>
        <Span className={`category-title-two ${className}`}>{categoryClassification}</Span>
      </SideContainer>
      <SideContainer>
        <GroupItens className="total">
          <Span className="category-title">Total</Span>
          <Span className="category-title">{total}</Span>
        </GroupItens>
        <TitleCardWrapper className="second-column">
          <Span className={`category-title-two ${className}`}>Percentual</Span>
          <Span className={`category-title-two ${className}`}>
            {percentage}
          </Span>
        </TitleCardWrapper>
      </SideContainer>
    </Container>
  );
};
