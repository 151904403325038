import React, { useState } from 'react';
import { Page } from 'components/templates/Page';
import DirecionalButton from 'components/organisms/Direcional';
import { ReportSpendingTable } from 'components/organisms/ReportSpendingTable';
import SemiannualReportTitle from 'components/molecules/SemiannualReportTitle';
import { CarouselReport } from 'components/molecules/CarouselReport';
import { getHousing } from 'development/report';
import { Redirect } from 'react-router-dom';

export default function Report() {
  const [currentPage, setCurrentPage] = useState('spendings');
  const finishedPage = false
  return finishedPage ? (
    <Page.RootContainer>
      <SemiannualReportTitle
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <CarouselReport currentPage={currentPage} months={getHousing().months} />
      <ReportSpendingTable currentPage={currentPage} />
      <DirecionalButton />
    </Page.RootContainer>
  ) : <Redirect to="/under-construction" />
}
