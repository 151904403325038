import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React, { useState, useEffect } from 'react';
import OptionOverlay from 'components/molecules/OptionOverlay';
import { CardContainer, Container, GroupIcons } from './styles';
import { generateClassName } from 'utils/text';

export const InstallmentPurchaseTable = ({
  body,
  setShowModal,
  showDeleteModal,
  setShowDeleteModal,
  nameTable,
  totalCategoryValue,
  totalCategoryPercentage,
  showEditIconOnTitle,
  typeCard,
  className,
  setShowAddModal,
  setSelectedItem,
  selectedItems,
  setSelectedItems,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);

  const header = [
    'Nome',
    'Categoria',
    'Item',
    'Total de parcelas',
    'Valor da parcela',
    'Valor restante',
  ]

  useEffect(() => {
    if (!selectedAll) {
      setSelectedItems([]);
    } else {
      const auxArray = [];
      body.forEach(item => {
        auxArray.push(item.id);
      });
      setSelectedItems(auxArray);
    }
  }, [selectedAll, body, setSelectedItems]);

  useEffect(() => {
    const sameItensQuantity = selectedItems.length === body.length;
    const nonZeroItemsQuantity = selectedItems.length > 0;
    setSelectedAll(sameItensQuantity && nonZeroItemsQuantity);
  }, [body.length, selectedItems]);

  useEffect(() => {
    if (body.length === 0) {
      setSelectedAll(false);
    }
  }, [body.length, showDeleteModal]);

  return (
    <CardContainer>
      <BudgetTableTitle
        setShowModal={setShowModal}
        nameTable={nameTable}
        total={totalCategoryValue}
        percentage={totalCategoryPercentage}
        showEditIcon={showEditIconOnTitle}
        className={typeCard}
      />
      <Container>
        <Table className="receipt-table">
          <thead>
            <Tr className="receipt-table-title debts">
              <Th className="budget-table-title debts checkbox first-table-border">
                <Checkbox
                  checked={selectedAll}
                  onClick={() => setSelectedAll(!selectedAll)}
                />
              </Th>
              {header.map((item, index) => (
                <Th className={generateClassName({
                  'budget-table-title debts': true,
                  'first': item === 'Nome',
                })} key={index}>
                  {item}
                </Th>
              ))}
              <Th className="budget-table-title debts empty last-table-border" />
            </Tr>
          </thead>
          <tbody>
            {body.map((column, rowIndex) => (
              <Tr className="receipt-table-body" key={rowIndex}>
                <Td className="receipt-table-body checkbox">
                  <Checkbox
                    checked={selectedItems.includes(column.id)}
                    onClick={() => {
                      if (selectedItems.includes(column.id)) {
                        setSelectedItems(
                          selectedItems.filter(item => item !== column.id)
                        );
                        return;
                      }

                      const auxArray = [...selectedItems];
                      auxArray.push(column.id);
                      setSelectedItems(auxArray);
                    }}
                  />
                </Td>
                <Td className="receipt-table-body first">
                  {column.name}
                </Td>
                <Td className="receipt-table-body" >
                  {column.category.name}
                </Td>
                <Td className="receipt-table-body" >
                  {column.item.name}
                </Td>
                <Td className="receipt-table-body" >
                  {column.current_installment}/{column.total_installments}
                </Td>
                <Td className="receipt-table-body" >
                  {column.value}
                </Td>
                <Td className="receipt-table-body" >
                  {column.remaining_value}
                </Td>
                <Td className="receipt-table-body options">
                  <OptionOverlay
                    onClick={() => {
                      setShowModal(prev => !prev);
                      setSelectedItem(column);
                    }}
                    className={className}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <GroupIcons>
        <AddIcon
          className="receipt-table"
          onClick={() => {
            setShowAddModal(prev => !prev);
          }}
        />
        <TrashIcon
          className="receipt-table"
          onClick={() => {
            setShowDeleteModal(prev => !prev);
          }}
        />
      </GroupIcons>
    </CardContainer >
  );
};
