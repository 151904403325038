import styled from 'styled-components';

export const StyledSearchIcon = styled.svg`
  &.header {
    width: 22px;
    height: 22px;

    path {
      stroke: ${({ theme }) => theme.inputText};
    }
  }

  @media (max-width: 1440px) {
    &.header {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1024px) {
    &.header {
      width: 18px;
      height: 18px;
    }
  }
`;
