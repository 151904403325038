import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 31px;
  height: 31px;

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  @media (max-width: 1845px) {
    width: 23px;
    height: 23px;
  }
`;
