import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: min-content;
  height: 32px;
  display: flex;

  &.loading {
    display: none;
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.grayShadow};
  color: ${({ theme }) => theme.grayShadow};
  width: max-content;
  height: 32px;
  padding: 5px;
  gap: 8px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};

  &.selected {
    background-color: ${({ theme }) => theme.blueDarkOpacity};
    color: ${({ theme }) => theme.white};
    border: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.blueDarkOpacity};
    color: ${({ theme }) => theme.white};
    border: none;
  }
`;
