import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Container = styled.div`
  &.simple-modal {
    width: 23px;
    height: 23px;
  }

  &.delete-generic-dream {
      width: 23px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.sideBarHover};
      }

      @media (max-width: 1280px) {
        width: 12.27px;
        height: 12.8px;
        border-radius: 2.13px;
      }
    }
`;

export const StyledTrashIcon = styled.svg`
  &.receipt-table {
    width: 37px;
    height: 37px;
    border: 1px solid ${({ theme }) => theme.grayShadow};
    padding: 6px;
    border-radius: 8px;
    fill: ${({ theme }) => theme.grayShadow};
    cursor: pointer;

    path {
      width: 18.86px;
      height: 20.26px;
      fill: ${({ theme }) => theme.grayShadow};
    }

    &:hover {
      background-color: ${({ theme }) => theme.grayOpacity};
      border: none;
      path {
        fill: ${({ theme }) => theme.white};
      }
    }
  }

  &.simple-modal {
    width: 23px;
    height: 23px;
    cursor: pointer;

    path {
      width: 18px;
      height: 23px;
    }

    &.debts {
      path {
        fill: ${({ theme }) => theme.redMedium};
      }
    }

    &.delete {
      path {
        fill: ${({ theme }) => theme.redMedium};
      }
    }

    &.spendings {
      path {
        fill: ${({ theme }) => theme.blueSky};
      }
    }

    &.receipt {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      stroke: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }
  }

  &.loading {
    width: 37px;
    height: 37px;
    padding: 6px;
    border-radius: 8px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;

    path {
      fill: none;
    }
  }

  &.delete-generic-dream {
    width: 18px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.white};
    }

    @media (max-width: 1280px) {
      width: 9.6px;
      height: 8.53px;
    }
  }
`;
