import styled from "styled-components";

export const CardContainer = styled.div`
    margin-top: 36px;
    display: flex;
    gap: 32px;

    @media (max-width: 1023px) {
        overflow: auto;
        gap: 17.066px;
        margin-top: 18.5px;
        padding-right: 16px;
        padding-top: 6px;
        padding-bottom: 12px;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.grayWebkitScrollbarThumb};
        border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.webkitScrollbarTrack};
    }

`;