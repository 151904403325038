import styled from 'styled-components';

export const CardContainer = styled.div`
  display: block;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.white};
  padding-top: 24px;
  padding-left: 22px;
  padding-right: 22px;
  margin: 5px auto 24px;
  border-radius: 16px;

  &.no-footer {
    padding-bottom: 24px;
  }

  @media (max-width: 1440px) {
    padding-top: 18px;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding-bottom: 0;

  @media (max-width: 1280px) {
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.graySilver};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.grayPale};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &.loading {
    overflow: hidden;
  }
`;

export const GroupIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  height: min-content;
  gap: 13px;
  margin-top: 23px;
  padding-bottom: 24px;
`;
