import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const StyledContainer = styled.div`
  width: 190px;
  height: 100%;
  min-width: 180px;
  min-height: 250px;
  display: flex;
  justify-content: flex-start;
`;

export const StyledBar = styled(Bar)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: flex-start;
  margin: 0;
`;

export const LoadingContainer = styled.div`
  width: 118px;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  border-radius: 5px;
`;
