import styled from "styled-components";

export const GoalsPanelContainer = styled.div`
    padding-bottom: 32px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    @media (max-width: 1023px) {
        padding-bottom: 12px;
    }

    @media (max-width: 490px) {
        justify-content: center;
    }
`;