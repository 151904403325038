import styled from 'styled-components';

export const Td = styled.td`
  &.receipt-table-body {
    text-align: ${props => (props.first ? 'left' : 'center')};
    color: ${({ theme }) => theme.grayShadow};
    vertical-align: middle;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    height: 44px;
    padding: 12px 16px;
  }
  
  &.receipt-table-body.first {
    text-align: left;
    max-width: 15.78vw;
    min-width: 268px;
  }

  &.receipt-table-body.checkbox {
    width: min-content;
    text-align: left;
  }

  &.receipt-table-body.options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &.debts {
    width: min-content;
    height: min-content;
    text-align: right;
  }
`;
