import { useState, useEffect } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { startOfMonth, endOfMonth } from 'date-fns';

const useDashboard = () => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [patrimonies, setPatrimonies] = useState([
    {
      financial_active: '0',
      passive_patrimonies: '0',
      liquid_patrimony: '0',
    },
  ]);
  const [mainAssets, setMainAssets] = useState([
    {
      fund: 'A definir',
      progress: {
        percent: '0%',
        scale: 0,
      },
    },
    {
      fund: 'A definir',
      progress: {
        percent: '0%',
        scale: 0,
      },
    },
    {
      fund: 'A definir',
      progress: {
        percent: '0%',
        scale: 0,
      },
    },
  ]);

  const [recentSpendings, setRecentSpending] = useState([]);
  const [budgetCompositon, setBudgetCompositon] = useState({
    estimated: {
      scale: 0,
      receipts: {
        formatted: 'R$ 0',
        raw: 0,
      },
      spendings: {
        formatted: 'R$ 0',
        raw: 0,
      },
      investment: {
        formatted: 'R$ 0',
        raw: 0,
      },
      debts: {
        formatted: 'R$ 0',
        raw: 0,
      },
    },
    actual: {
      scale: 0,
      receipts: {
        formatted: 'R$ 0',
        raw: 0,
      },
      spendings: {
        formatted: 'R$ 0',
        raw: 0,
      },
      investment: {
        formatted: 'R$ 0',
        raw: 0,
      },
      debts: {
        formatted: 'R$ 0',
        raw: 0,
      },
    },
  });

  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const date = new Date();
      const start = startOfMonth(date);
      const end = endOfMonth(date);
      setLoading(true);
      try {
        const [
          { data: patrimonies },
          { data: mainAssets },
          { data: spendings },
          { data: budget },
          { data: goalsList },
        ] = await Promise.all([
          api.get(`/patrimony/overview/${userId}`, {
            params: { formated_value: true },
          }),
          api.get(`/strategies/main_assets/${userId}`),
          api.get(`/entry/recent-spendings/${userId}`, {
            params: {
              limit_item_description: 7,
              limit_category_description: 4,
              formated_value: true,
            },
          }),
          api.get(`/budget/composition/${userId}`, {
            params: {
              formated_value: true,
              start,
              end,
            },
          }),
          api.get(`/goal/${userId}`, {
            params: {
              formated_value: true,
            },
          }),
        ]);
        setPatrimonies(patrimonies);
        setMainAssets(mainAssets);
        setRecentSpending(spendings);
        setBudgetCompositon(budget);
        setGoals(goalsList);

        console.log(patrimonies, mainAssets, spendings, budget, goalsList);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        toast.error('Ocorreu um erro ao buscar os dados do dashboard.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return {
    patrimonies,
    mainAssets,
    recentSpendings,
    budgetCompositon,
    goals,
    loading,
  };
};

export default useDashboard;
