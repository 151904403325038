import React, { useState, useEffect, useContext } from 'react';
import { Container, Group, Wrapper, Line } from './styles';
import { H4 } from 'components/atoms/Headings/H4';
import { SearchBar } from 'components/molecules/SearchBar';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { H5 } from 'components/atoms/Headings/H5';
import { DropdownList } from 'components/atoms/DropdownList';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { useHistory, useLocation } from 'react-router-dom';

export const MapHeaderBasic = ({
  className = '',
}) => {
  const history = useHistory();
  const location = useLocation();
  const { loading } = useContext(GeneralContext);
  const [activeSection, setActiveSection] = useState();

  useEffect(() => {
    const url = `${location.pathname}${location.search}${location.hash}`;
    const lastSegment = url.substring(url.lastIndexOf('/') + 1);

    const actions = {
      spending: () => setActiveSection('Planejar'),
      budget: () => setActiveSection('Mapear'),
    };

    if (actions[lastSegment]) {
      actions[lastSegment]();
    }
  }, [location]);

  const handleSectionClick = sectionName => {
    setActiveSection(sectionName);
    if (sectionName === 'Mapear') {
      history.push('/finances/budget');
    }
    if (sectionName === 'Atuar') {
      history.push('/under-construction');
    }
  };

  const dropdownOptions = [
    {
      label: 'Gastos',
      value: 'gastos',
      onClick: () => history.push('/management/spending'),
    },
    {
      label: 'Extrato por período',
      value: 'Extrato por períodoto',
      onClick: () => history.push('/management/extract'),
    },
    { label: 'Relatório de 6 meses', value: 'Relatório de 6 meses' },
    {
      label: 'Pote detalhado',
      value: 'Pote detalhado',
      onClick: () => history.push('/management/detail'),
    },
  ];

  if (loading) {
    return (
      <Wrapper className="loading">
        <GenericLoading className="goals-panel-title" />
        <GenericLoading className="goals-panel-searchbar" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container className={className}>
        <Group className="client-side">
          <H4
            className={`map-header gray-silver`}
          >
            Gestão Orçamentária
          </H4>
        </Group>
        <Group className={` ${loading ? 'map-loading' : ''}`}>
          <H5
            className={`map-header ${activeSection === 'Mapear' ? 'map-border' : ''}`}
            onClick={() => handleSectionClick('Mapear')}
          >
            Mapear
          </H5>
          <Line>
            <DropdownList
              options={dropdownOptions}
              className={`${activeSection === 'Planejar'
                ? 'map-header map-border'
                : loading
                  ? 'map-header-loading'
                  : 'map-header'
                }`}
              onClick={() => handleSectionClick('Planejar')}
            />
          </Line>
          <H5
            className={` ${activeSection === 'Atuar'
              ? 'map-header map-border'
              : loading
                ? 'map-header-loading'
                : 'map-header'
              }`}
            onClick={() => handleSectionClick('Atuar')}
          >
            Atuar
          </H5>
        </Group>
        <Group className={`input-side ${loading && 'loading'}`}>
          <SearchBar
            className={`header ${loading && 'invisible'}`}
            placeholder="Pesquisar..."
          />
        </Group>
      </Container>
    </Wrapper>
  );
};
