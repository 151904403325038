import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 46px;
  align-items: center;
`;

export const ContainerWrapper = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 9px;
`;

export const GroupItens = styled.div`
  display: flex;
  gap: 129px;
  justify-content: space-between;
`;
