import styled from 'styled-components';

export const Span = styled.span`
  &.patrimony-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${({ theme }) => theme.blueRoyal};
    text-align: center;
    flex-grow: 1;
    font-family: ${({ theme }) => theme.fonts.PoppinsBold.fontFamily};
    font-size: 3.5rem;
    font-weight: 700;

    @media (min-width: 1921px) and (max-width: 2160px) {
      font-size: 4rem;
    }
    @media (min-width: 1441px) and (max-width: 1920px) {
      font-size: 3.5rem;
    }
    @media (min-width: 1025px) and (max-width: 1440px) {
      font-size: 2.5rem;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 2.5rem;
    }
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
    @media (orientation: portrait) and (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  &.dream-investment {
    color: ${({ theme }) => theme.white};
    font-size: 12px;
    font-weight: 300;
  }

  &.sidebar-icon-description {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 4%;
    margin: 1 0 0 0;

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
      border-radius: 5px;
      cursor: pointer;
    }
  }

  &.sidebar {
    width: min-content;
    transition: linear 0.5s ease;
    cursor: pointer;
    border-radius: 8px;
    color: ${({ theme }) => theme.white};
    font-weight: 500;
    white-space: nowrap;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 2%;
    border-radius: 8px;
    font-size: 14px;

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
    }
  }

  &.category-title {
    display: inline-block;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};

    @media (max-width: 1440px) {
      font-size: 18px;
    }
  }

  &.table-title {
    display: inline-block;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    &.color-blue {
      color: ${({ theme }) => theme.blueMidnight};
    }
  }

  &.category-title-two {
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 16px;
    color: ${({ theme }) => theme.bluePale};

    &.debts {
      color: ${({ theme }) => theme.redBlood};
    }

    &.investments {
      color: ${({ theme }) => theme.greenDark};
      opacity: 0.55;
    }

    @media (max-width: 1440px) {
      font-size: 12px;
    }
  }

  &.simple-modal {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: ${({ theme }) => theme.grayShadow};
  }

  &.simple-modal.body {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.grayShadow};
  }

  &.simple-modal.body.highlighted {
    font-weight: bold;
    padding-left: 1%;
  }

  &.passive {
    color: ${({ theme }) => theme.redMedium};
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.PoppinsMedium.fontFamily};

    &.blue {
      color: ${({ theme }) => theme.blueRoyal};
    }

    @media (max-width: 1023px) {
      font-size: 8.53px;
      line-height: 8.53px;
    }
    &.font-regular {
      font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    }
  }

  &.selected-patrimony {
    color: ${({ theme }) => theme.white};
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.PoppinsMedium.fontFamily};

    @media (max-width: 1023px) {
      font-size: 8.53px;
      line-height: 8.53px;
    }
  }

  &.goal-panel {
    color: ${({ theme }) => theme.blueRoyal};
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.PoppinsMedium.fontFamily};
    &.hovered {
      color: ${({ theme }) => theme.white};
    }

    @media (max-width: 1023px) {
      font-size: 8.53px;
      line-height: 8.53px;
    }
  }

  &.goals-panel {
    &.financial-independence {
      color: ${({ theme }) => theme.white};

      &.title {
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 450;
        width: 229px;

        @media (max-width: 1023px) {
          width: 122.1px;
          font-size: 12.8px;
          line-height: 12.8px;
        }
      }

      &.time-to-achieve {
        font-size: 0.8rem;
        line-height: 0.8rem;
        font-weight: 400;
        margin-top: 13px;
      }
      font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};

      @media (max-width: 1023px) {
        font-size: 8.5px;
        line-height: 8.5px;
        margin-top: 6.9px;
      }
    }

    &.section-title {
      font-size: 0.8rem;
      line-height: 0.8rem;
      font-weight: 400;
      font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};

      @media (max-width: 1023px) {
        font-size: 8.533px;
        line-height: 8.533px;
      }
    }

    &.bpl {
      margin-top: 43px;

      @media (max-width: 1023px) {
        margin-top: 22.94px;
      }
    }

    &.total-financial-independence {
      color: ${({ theme }) => theme.greenShadow};
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 600;
      font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};

      @media (max-width: 1023px) {
        font-size: 9.6px;
        line-height: 9.6px;
      }
    }
  }

  &.generic-dream-modal {
    color: ${({ theme }) => theme.graySilver};
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    @media (max-width: 1023px) {
      font-size: 7.47px;
      line-height: 7.47px;
    }
  }

  &.extract-menu-header {
    margin: 8px 0;
    color: ${({ theme }) => theme.blueMidnight};
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.PoppinsBold.fontFamily};
    font-weight: bold;
    @media (max-width: 1440px) {
      font-size: 1.1rem;
      line-height: 1.1rem;
    }
  }
  &.extract.dropdown {
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 700;
    color: ${({ theme }) => theme.blueMidnight};
  }
  &.extract.date-dropdown-year {
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 700;
    color: ${({ theme }) => theme.blueMidnight};
  }
  &.extract.date-dropdown-month-name {
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 450;
    color: ${({ theme }) => theme.grayShadow};
    @media (max-width: 1440px) {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
  &.extract-title {
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 700;
    color: ${({ theme }) => theme.blueMidnight};
    @media (max-width: 1440px) {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
  }
  &.extract-item {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: 700;
    color: ${({ theme }) => theme.grayShadow};
    @media (max-width: 1440px) {
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }

  &.extract-title-item {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: 700;
    color: ${({ theme }) => theme.blueMidnight};
    @media (max-width: 1440px) {
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }
  &.extract-title-item.secondary {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: 700;
    color: ${({ theme }) => theme.grayShadow};
  }

  &.extract-title.secondary {
    font-size: 0.6rem;
    line-height: 0.6rem;
    font-weight: 700;
    color: ${({ theme }) => theme.grayShadow};
  }
  &.detailed-extract-item.title {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: 0.6rem;
    line-height: 0.6rem;
    font-weight: 400;
  }
  &.detailed-extract-item.title.installments-header {
    color: ${({ theme }) => theme.pinkInstallmentPurchases};
  }
  &.detailed-extract-item.body {
    font-weight: 700;
    color: ${({ theme }) => theme.blueMidnight};
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
  &.detailed-extract-item.body.installments-header {
    color: ${({ theme }) => theme.pinkInstallmentPurchases};
    font-weight: 700;
  }
  &.detailed-extract-footer {
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    line-height: 1rem;
  }
`;