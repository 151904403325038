import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background-color: ${props => props.theme.white};
    margin-top: 34px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 64px;

    @media (max-width: 1023px) {
        margin-top: 18.1px;
        border-radius: 8.5px;
        gap: 17.1px;
        margin-bottom: 34.1px;
    }
`;

export const ScrollContainer = styled.div`
    height: max-content;
    gap: 32px;

    @media (max-width: 1023px) {
        gap: 17.1px;
        overflow: auto;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 1600px;
    height: min-content;
    overflow: auto;
    min-width: 375px;

    &.button {
        min-width: 0;
    }
`;

export const TitleContainer = styled.div`
    margin-top: 45px;
    height: min-content;

    @media (max-width: 1023px) {
        margin-top: 24px;
    }
`;

export const IntroductionContainer = styled.div`
    border: 1px solid ${props => props.theme.lilca};
    border-radius: 16px;
    width: 612px;
    height: 304px;
    padding: 54px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1023px){
        border: 0.5px solid ${props => props.theme.lilca};
        border-radius: 8.5px;
        width: 326.4px;
        height: 162.1px;
        padding: 28.8px 26.7px;
    }
`;
