import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.26vw;

  @media (max-width: 1080px) {
    gap: 4px;
  }
`;
