import React, { useState } from 'react';
import { DreamCard } from 'components/templates/DreamCard';
import { FinancialIndependenceModal } from 'components/molecules/FinancialIndependenceModal';
import { formatOutputMoney } from 'utils/numbers';

export const FinancialIndependenceGoal = ({
  dreamProps,
  onToggleDreamStatus,
}) => {
  const [showModal, setShowModal] = useState(false);
  const realized = dreamProps?.done_at;

  const editModal = () => {
    setShowModal(prev => !prev);
  };

  const handleToggleDreamStatus = () => {
    onToggleDreamStatus(dreamProps?.id, dreamProps?.done_at);
  };

  const body = {
    realized: (
      <DreamCard.RootContainer
        className="goal-panel realized-generic-dream"
        backgroundImage={dreamProps?.url}
      >
        <DreamCard.Icon
          icon="round-verified"
          className="realized-generic-dream"
        />
        <DreamCard.Text
          text={dreamProps?.name}
          className="goals-panel generic-dream realized"
        />
        <DreamCard.GroupItens className="on-center">
          <DreamCard.Icon
            icon="pencil"
            className="financial-independence"
            onClick={editModal}
          />
          <DreamCard.Icon
            icon="verified"
            className="realized-dream"
            onClick={handleToggleDreamStatus}
          />
        </DreamCard.GroupItens>
      </DreamCard.RootContainer>
    ),
    unrealized: (
      <DreamCard.RootContainer
        className="goal-panel financial-independence"
        backgroundImage={dreamProps?.url}
      >
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text={dreamProps?.name}
            className="goals-panel financial-independence title"
          />
          <DreamCard.GroupItens>
            <DreamCard.Icon
              icon="pencil"
              className="financial-independence"
              onClick={editModal}
            />
            <DreamCard.Icon
              icon="verified"
              className="financial-independence"
              onClick={handleToggleDreamStatus}
            />
          </DreamCard.GroupItens>
        </DreamCard.GroupItens>
        <DreamCard.Text
          text={dreamProps?.formatted_when}
          className="goals-panel financial-independence time-to-achieve"
        />
        <DreamCard.Text
          text="Base de Plena Liquidez (B.P.L.)"
          className="goals-panel financial-independence section-title bpl"
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text={formatOutputMoney(dreamProps?.bpl)}
            className="goals-panel financial-independence progress-information money"
          />
          <DreamCard.Text
            text={formatOutputMoney(dreamProps?.financial_active_value_bpl)}
            className="goals-panel financial-independence progress-information money"
          />
        </DreamCard.GroupItens>
        <DreamCard.ProgressBar
          className="goals-panel"
          progress={dreamProps?.bpl_percentage_completed}
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text="Total investido"
            className="goals-panel financial-independence progress-information percentage"
          />
          <DreamCard.Text
            text={formatOutputMoney(dreamProps?.bpl_percentage_completed)}
            className="goals-panel financial-independence progress-information percentage"
          />
        </DreamCard.GroupItens>
        <DreamCard.Text
          text="Independência Financeira Total"
          className="goals-panel financial-independence section-title total-financial-independence"
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text={formatOutputMoney(dreamProps.saved_money)}
            className="goals-panel financial-independence progress-information money"
          />
          <DreamCard.Text
            text={formatOutputMoney(dreamProps?.value)}
            className="goals-panel financial-independence progress-information money"
          />
        </DreamCard.GroupItens>
        <DreamCard.ProgressBar
          className="goals-panel"
          progress={dreamProps?.percentage_completed}
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text="Total investido"
            className="goals-panel financial-independence progress-information percentage"
          />
          <DreamCard.Text
            text={`${dreamProps?.percentage_completed || 0}%`}
            className="goals-panel financial-independence progress-information percentage"
          />
        </DreamCard.GroupItens>
      </DreamCard.RootContainer>
    ),
  };

  return (
    <>
      {showModal && (
        <FinancialIndependenceModal
          setShowModal={setShowModal}
          showModal={showModal}
          dreamProps={dreamProps}
        />
      )}
      {body[realized ? 'realized' : 'unrealized']}
    </>
  );
};

export default FinancialIndependenceGoal;
