import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 719px;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
`;
export const Progress = styled.div`
  display: flex;
  height: auto;
  border-radius: 4px;
  overflow: hidden;
  width: 627px;
`;

export const ProgressSegment = styled.div`
  height: 100%;
  width: ${({ width }) => width}%;
  background-color: ${props => props.color};
`;

export const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 719px;
  margin-top: 8px;
`;

export const ProgressLabel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ProgressTotal = styled.div`
  margin-left: 10px;
`;
