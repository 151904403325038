import styled from "styled-components";

export const SealedIConContainer = styled.svg`
    &.goal-panel {
        width: 20.67px;
        height: 25.83px;
        min-width: 11px;
        min-height: 13.8px;
        
        path {
            stroke: ${({ theme }) => theme.blueRoyal};
        }

        path:first-child {
            width: 7.75px;
            height: 14.21px;
            min-width: 4.1px;
            min-height: 7.6px;
        }

        path:last-child {
            width: 20.67px;
            height: 11.63px;
            min-width: 11px;
            min-height: 6.2px;
        }
    }
`;