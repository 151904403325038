import { useState, useEffect, useCallback } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const defaultCard = {};

const useGoals = () => {
  const { id: userId } = useSelector(state => state.user.profile);

  const [goals, setGoals] = useState([]);
  const [card, setCard] = useState(defaultCard);
  const [financialBalance, setFinancialBalance] = useState(null);
  const [patrimonialBalance, setPatrimonialBalance] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchGoals = useCallback(async () => {
    if (!userId) {
      toast.error('Usuário não encontrado.');
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await api.get(`users/${userId}/goals`, {
        params: { overview: true },
      });
      const goalsList = response.data;
      setGoals(goalsList.goals || []);
      setCard(goalsList.card || defaultCard);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os dados dos sonhos.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const fetchBalances = useCallback(async () => {
    setLoading(true);
    try {
      const { data: balance } = await api.get(`goals/${userId}/seals/balance`);
      setFinancialBalance(balance.financial);
      setPatrimonialBalance(balance.patrimonial);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os saldos.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchGoals();
  }, [fetchGoals]);
  useEffect(() => {
    fetchBalances();
  }, [fetchBalances]);

  const createGoal = async goalData => {
    setLoading(true);
    try {
      const { data: newGoal } = await api.post(
        `users/${userId}/goals`,
        goalData
      );
      setGoals(prevGoals => [...prevGoals, newGoal]);
      toast.success('Meta criada com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao criar o sonho.');
    } finally {
      setLoading(false);
    }
  };

  const updateGoal = async (goalId, updatedData) => {
    setLoading(true);
    try {
      const { data: updatedGoal } = await api.put(
        `goals/${goalId}`,
        updatedData
      );
      setGoals(prevGoals =>
        prevGoals.map(goal => (goal.id === goalId ? updatedGoal : goal))
      );
      toast.success('Sonho atualizado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar o sonho.');
    } finally {
      setLoading(false);
    }
  };

  const deleteGoal = async goalId => {
    setLoading(true);
    try {
      await api.delete(`goals/${goalId}`);
      setGoals(prevGoals => prevGoals.filter(goal => goal.id !== goalId));
      toast.success('Sonho excluído com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao excluir o sonho.');
    } finally {
      setLoading(false);
    }
  };

  return {
    goals,
    loading,
    card,
    financialBalance,
    patrimonialBalance,
    createGoal,
    updateGoal,
    deleteGoal,
    fetchGoals,
    fetchBalances,
  };
};

export default useGoals;
