import styled from 'styled-components';

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 1200px;
  margin-top: 38px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  
  &.start {
    align-items: flex-start;
  }
`;

export const ColorBox = styled.div`
  display: flex;
  width: 20px;
  height: 20px 8px;
  border-radius: 4px;

  &.blue {
    background-color: ${({ theme }) => theme.blueRoyal};
  }
  &.blue-opacity {
    background-color: ${({ theme }) => theme.opacityBlueSky};
  }
  &.blue-sky {
    background-color: ${({ theme }) => theme.blueSky};
  }
  &.lilac-opacity {
    background-color: ${({ theme }) => theme.opacityBlueLight};
  }
`;

export const Line = styled.div`
  width: 30px;
  height: 5px;
  margin-right: 8px;
  border: ${props => (props.dashed ? '2px dashed' : 'none')};
  border-color: white;

  &.gray {
    background-color: ${({ theme }) => theme.graySilver};
  }
  &.green {
    background-color: ${({ theme }) => theme.greenMedium};
  }
`;
