import styled from "styled-components";

export const Container = styled.div`
    &.gallery-modal {
        width: min-content;
        height: min-content;
    }
`;

export const StyledSelectImageCard = styled.svg`
    &.gallery-modal {
        width: 15.33px;
        height: 15px;

        @media (max-width: 1023px) {
            width: 8.176px;
            height: 8px;
        }
    }
`;