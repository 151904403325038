import styled from "styled-components";

export const Container = styled.div`
    &.gallery-modal {
        width: 111px;
        height: 82px;
        padding-left: 28px;
        padding-top: 20px;
        padding-bottom: 21px;
        padding-right: 27px;
        border-radius: 8px;
        border: 1px dotted ${props => props.theme.graySilver};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (max-width: 1023px) {
            width: 59.2px;
            height: 43.74px;
            padding-left: 14.94px;
            padding-top: 10.7px;
            padding-bottom: 11.2px;
            padding-right: 14.4px;
            border-radius: 4.27px;
            border: 0.53px dotted ${props => props.theme.graySilver};
        }
    }
`;

export const Group = styled.div`
    &.gallery-modal {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 56px;
        height: 41px;
        
        @media (max-width: 1023px) {
            width: 29.87px;
            height: 21.87px;
        }
    }
`;