import styled from "styled-components";

export const Container = styled.div`
    padding-top: 32px;
    background-color: ${props => props.theme.white};
    width: 100%;
    margin-top: 34px;
    border-radius: 16px;
    padding-left: 27px;
    padding-right: 23px;
    padding-bottom: 32px;
    
    @media (max-width: 1023px){
        padding-bottom: 17.1px;
        padding-left: 14.4px;
        padding-right: 12.3px;
        padding-top: 17.1px;
        margin-top: 18.1px;
        border-radius: 8.5px;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: baseline;
`;

export const LoadingContainer = styled.div`
    width: 100%;
    overflow: auto;
`;

export const ScrollContainer = styled.div`
    width: 100%;
    margin-top: 28px;

    @media (max-width: 1023px) {
      margin-top: 14.9px;
    }
`;