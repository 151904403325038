import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import { formatPercentage } from 'utils/numbers';

export const ModalInputPercentage = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = '',
}) => {
  const percentageSymbol = '% a.m';

  const [inputValue, setInputValue] = React.useState(
    formatPercentage(defaultValue)
  );

  React.useEffect(() => {
    getInputValue(inputValue);
  }, [getInputValue, inputValue]);

  const handleChange = event => {
    const brutalValue = event.target.value;
    const currentValue = brutalValue.replace(percentageSymbol, '');
    const formattedValue = formatPercentage(currentValue);
    setInputValue(formattedValue);
  };

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Input
        className={className}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Label>
  );
};
