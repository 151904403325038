import * as Yup from 'yup';

export const receiptSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  classification: Yup.string().required('Classificação é obrigatória'),
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data de início é obrigatória'),
  description: Yup.string(),
  recurring: Yup.boolean(),
});

export const spendingSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  classification: Yup.string().required('Classificação é obrigatória'),
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data de início é obrigatória'),
  description: Yup.string(),
  recurring: Yup.boolean(),
});

export const debtsSchema = Yup.object().shape({
  name: Yup.string(),
  classification: Yup.string().required('Classificação é obrigatória'),
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data de início é obrigatória'),
  description: Yup.string(),
  installment: Yup.string().required('Parcelamento é obrigatório'),
});

export const investmentSchema = Yup.object().shape({
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data é obrigatória'),
});

export const createInstallmentsSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  value: Yup.number()
    .typeError('O valor deve ser um número')
    .required('Valor é obrigatório'),
  item_id: Yup.number().required('É obrigatório selecionar um item!'),
  category_id: Yup.number().required('É obrigatório escolher uma categoria!'),
  currentInstallment: Yup.number()
    .required('Insira qual a parcela atual!')
    .test(
      'is-valid-installment',
      'A parcela atual deve ser maior que 0 e menor ou igual ao total de parcelas.',
      function (current_installment) {
        const { totalInstallments } = this.parent;
        return (
          current_installment > 0 && current_installment <= totalInstallments
        );
      }
    ),
  totalInstallments: Yup.number()
    .required('Insira o total de parcelas!')
    .positive('O total de parcelas deve ser maior que zero.')
    .integer('O total de parcelas deve ser um número inteiro.'),
});

export const updateRecorrenceSchema = async data => {
  const schema = Yup.object().shape({
    id: Yup.number().required(
      'Nenhum item foi selecionado para alterar a recorrência!'
    ),
    recurrent: Yup.boolean().required(
      'Nenhum item foi selecionado para alterar a recorrência!'
    ),
  });

  try {
    await schema.validate(data);
    return {
      success: true,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

const classificationOptions = [
  'Mensal Comprometido',
  'Eventual Comprometido',
  'Mensal Flexível',
  'Eventual Flexível',
];

export const createItemSchema = async data => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'O nome deve ter pelo menos 3 letras!')
      .required('Preencha o nome do item!'),
    classification: Yup.string()
      .oneOf(
        [
          'Mensal Comprometido',
          'Mensal Flexível',
          'Eventual Flexível',
          'Eventual Comprometido',
        ],
        'Classificação inválida! Escolha uma das classificações válidas.'
      )
      .required('Preencha a classificação do item!'),
    value: Yup.number().required('Preencha o valor do item!'),
    recurrent: Yup.boolean().default(false),
    when: Yup.number().nullable(),
  });

  try {
    await schema.validate(data);

    const needsWhen =
      data.classification === classificationOptions[0] ||
      data.classification === classificationOptions[1];
    if (needsWhen && !data.when) {
      throw new Error('O campo de vencimento deve ser preenchido!');
    }
    let formatedData = { ...data };
    delete formatedData.maturity;

    return {
      success: true,
      data: formatedData,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

export const updateItemSchema = async data => {
  const schema = Yup.object().shape({
    id: Yup.number().required('Nenhum item foi selecionado para alterar!'),
    name: Yup.string()
      .min(3, 'O nome deve ter pelo menos 3 letras!')
      .required('Preencha o nome do item!'),
    classification: Yup.string(),
    value: Yup.number(),
    recurrent: Yup.boolean().default(false),
    credit_card: Yup.boolean().default(false),
    when: Yup.number().nullable(),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    if (!classificationOptions.includes(validatedData.classification)) {
      throw new Error(
        'Classificação inválida! Escolha uma das classificações válidas.'
      );
    }

    const needsWhen =
      data.classification === classificationOptions[0] ||
      data.classification === classificationOptions[1];
    if (needsWhen && !data.when) {
      throw new Error('O campo de vencimento deve ser preenchido!');
    }

    return {
      success: true,
      data: validatedData,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export const createCategorySchema = async data => {
  const schema = Yup.object().shape({
    name: Yup.string().required('Preencha o nome da categoria!'),
    classification: Yup.string().required(
      'Preencha a classificação da categoria!'
    ),
    type: Yup.string().required('Preencha o tipo da categoria!'),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: true,
    });
    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

export const updateCategorySchema = async data => {
  const schema = Yup.object().shape({
    name: Yup.string().required('Preencha o nome da categoria!'),
    classification: Yup.string().required(
      'Preencha a classificação da categoria!'
    ),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });
    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

export const genericDreamSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatória'),
  deadline: Yup.date()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Prazo é obrigatório'),
  imageDream: Yup.string().required('Imagem é obrigatória'),
});
