import styled from "styled-components";

export const ModalDatePickerContainer = styled.div`
    &.generic-dream-modal-datepicker {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
        border-bottom: 1px solid ${props => props.theme.grayShadow};

        &.full-width {
            width: 100% !important;
        }

        @media (max-width: 1023px) {
            border-bottom: 0.53px solid ${props => props.theme.grayShadow};
        }
    }
`;

export const ModalDateContainer = styled.div`
    &.generic-dream-modal-datepicker {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 33px;
        margin-top: 14px;

        @media (max-width: 1440px) {
            height: 30px;
            margin-top: 12px;
        }

        @media (max-width: 1023px) {
            height: 24px;
            margin-top: 9.6px;
        }
    }
`;

export const ModalDatePickerContent = styled.div`
    &.generic-dream-modal-datepicker {
        display: flex;
        padding: 8.5px 5px 8.5px 8px;
        width: 100%;
        height: 33px;
        font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
        font-size: 0.8rem;
        line-height: 0.8rem;
        font-weight: 400;
        color: ${props => props.theme.graySilver};

        @media (max-width: 1440px) {
            padding: 7.5px 5px 7.5px 8px;
            height: 30px;
            font-size: 0.7rem;
            line-height: 0.7rem;
        }

        @media (max-width: 1023px) {
            padding: 5.5px 5px 5.5px 8px;
            height: 24px;
            font-size: 0.6rem;
            line-height: 0.6rem;
        }
    }
`;