import styled from "styled-components";

export const GraphContainer = styled.div`
    &.financial-assets {
        width: 784px;
        height: 512px;
        border-radius: 16px;
        padding: 48px;
        background-color: ${props => props.theme.white};

        @media (max-width: 1023px) {
            width: 418.1px;
            height: 273.1px;
            border-radius: 8.5px;
            padding: 25.6px;
        }
    }
`;

export const GraphWrapper = styled.div`
    &.financial-assets {
        display: flex;
        width: 100%;
    }
`;

export const LabelContainer = styled.div`
    &.financial-assets {
       margin-left: 112px;
       margin-top: 228px;

       @media (max-width: 1023px) {
        margin-left: 59.7px;
        margin-top: 121.6px;
       }
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    width: 162px;
    height: 17px;
    border-radius: 32px;
    background-color: ${props => props.backgroundColor};
    margin-top: 4px;

    @media (max-width: 1023px) {
        display: flex;
        width: 86.4px;
        height: 9.1px;
        border-radius: 17.1px;
        margin-top: 2.1px;
    }

    &.first {
        margin-top: 0;
    }
`;