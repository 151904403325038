import styled from "styled-components";

export const StyledCalendarIconContainer = styled.div`
    &.generic-dream-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 17px;
        cursor: pointer;

        @media (max-width: 1024px){
            width: 9.07px;
            height: 9.07px;
        }
    }
`;

export const StyledCalendarIcon = styled.svg`
    &.generic-dream-modal {
        width: 14px;
        height: 14px;

        @media (max-width: 1024px){
            width: 7.47px;
            height: 7.47px;
        }
    }
`;