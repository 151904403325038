import React from 'react';
import { Page } from 'components/templates/Page';
import DirecionalButton from 'components/organisms/Direcional';
import RetirementPlanningCard from 'components/organisms/RetirementPlanningCard';
import { Card } from 'components/templates/Card';
import GraficBarLine from 'components/atoms/GraficBarLine';
import { Table } from 'components/templates/Tables';
import LegendBarLine from 'components/molecules/LegendBarLine';
import { Redirect } from 'react-router-dom';

export default function PlanningRetirement() {
  const [currentPage, setCurrentPage] = React.useState('financialPatrimony');
  const finishedPage = false

  return finishedPage ? (
    <Page.RootContainer>
      <RetirementPlanningCard
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Card.Container className="spending-table grafic">
        <Table.TitleButton
          nameTable="Gráfico da Independência Financeira"
          className=" patrimony red font-regular none"
        />
        <LegendBarLine />
        <GraficBarLine />
      </Card.Container>
      <DirecionalButton />
    </Page.RootContainer>
  ) : <Redirect to="/under-construction" />
}
