import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React from 'react';
import { generateClassName } from 'utils/text';

export const FlexibleMonthlyTable = ({ flexibleMonthly, currentDate, setCurrentDate, onChangeDate }) => {
  const columnOrder = [
    'item_name',
    'category_name',
    'estimated',
    'accomplished',
    '  ',
    'balance_to_spend',
  ];

  const header = [
    'Item',
    'Categoria',
    'Estimado',
    'Realizado',
    '  ',
    'Saldo para gastar',
  ]

  return (
    <Card.Container className="spending-table">
      <Table.Title
        nameTable="Mensal Flexível"
        type="month"
        className="budget month"
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        tooltipText="Acontece todo mês mas sem data específica para pagar"
        onChangeDate={onChangeDate}
      />
      <Table.Overflow>
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title spendings">
              {header.map((item, index) => (
                <Table.Th
                  className={`budget-table-title ${index === 0 &&
                    'first-table-border checkbox'}`}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {flexibleMonthly.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  let currentItem = column[item];

                  if (item === '  ') {
                    return (
                      <Table.Td
                        className="receipt-table-body spending"
                        key={index}
                      >
                        <Table.Progress
                          className="table-spending"
                          progressPercentage={column['percentage']}
                        />
                      </Table.Td>
                    );
                  }
                  if (item === 'percentage') {
                    return (
                      <Table.Td
                        className={`receipt-table-body blue-midnight`}
                        key={index}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  }

                  return (
                    <Table.Td
                      className={generateClassName({
                        'receipt-table-body': true,
                        'first-table-border checkbox': index === 0,
                        'first': index === 0,
                      })}
                      first={item === 'item'}
                      key={index}
                    >
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td></Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Overflow>
    </Card.Container>
  );
};
