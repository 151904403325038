import styled from 'styled-components';

export const Overlay = styled.div`
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  font-size: 15px;
  width: 16vw;
  min-width: min-content;
  position: absolute;
  padding: 15px;
  border-radius: 1rem;
  left: -120px;
  z-index: 999;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.graySilver};
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.5));
  opacity: 0.95;
  top: 30px;

  &.visible {
    display: block;
    @media (max-width: 1295px) {
      width: 20vw;
    }
  }

  &.color-debts,
  &.color-receipt,
  &.color-spending {
    background-color: ${({ theme }) => theme.graySilver};
  }

  &.budget {
    width: 90px;
    height: 25px;
    left: 90%;
    padding: 4px;
    border-radius: 4px;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};
  }

  &.report {
    line-height: 1.5;
  }

  &.title {
    height: 28px;
    padding: 6px;
    border-radius: 4px;
    font-size: 0.6rem;
    font-family: ${({ theme }) => theme.fonts.AperturaObliq};
    background-color: ${({ theme }) => theme.backgroundOverlay};

    &.visible {
      display: block;
      opacity: 0.8;
    }
  }

  &.home {
    left: 150%;
    font-size: 0.6rem;
    background-color: ${({ theme }) => theme.graySilver};
    color: ${({ theme }) => theme.white};
    padding: 14px;
    border-radius: 10px;
    pointer-events: none;
    white-space: wrap;
    width: 170px;
    height: auto;
    font-family: ${({ theme }) => theme.fonts.AperturaObliq};

    &.visible {
      display: block;
      opacity: 1;
    }

    :after {
      display: block;
    }
  }

  &.spending {
    font-size: 14px;

    @media (max-width: 1440px) {
      font-size: 12px;
    }
  }
`;