import styled from "styled-components";

export const Container = styled.div`
    margin-top: 34px;
    margin-bottom: 182.5px;

    @media (max-width: 1023px) {
        margin-top: 18.1px;
    }
`;

export const ProfileContainer = styled.div`
    margin-top: 40.5px;
    display: flex;
    margin-left: 229px;
    background-color: ${props => props.theme.white};
    width: 1142px;
    border-radius: 16px;
    justify-self: center;
    align-self: center;

    @media (max-width: 1023px) {
        margin-top: 21.6px;
        width: 609.1px;
        border-radius: 8.5px;
    }

    @media (max-width: 768px){
        margin-left: 0;
    }
`;

export const ProfileDescriptionContainer = styled.div`
    width: 512px;
    padding: 8px;
    margin-left: 32px;

    @media (max-width: 1023px) {
        width: 273.1px;
        padding: 4.3px;
        margin-left: 17.1px;
    }
`;

export const Wrapper = styled.div`
    width: 1600px;
    overflow: auto;

    &.button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;