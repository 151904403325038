import styled from 'styled-components';

export const ReceiptIconContainer = styled.svg`
  width: 2.55vw;
  height: 1.67vw;

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  &.report {
    width: 24px;
    height: 16px;
    min-width: 24px;
    min-height: 16px;
  }

  &.extract.receipts {
    width: 24px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.blueNavy};
    }

    &.selected path {
      fill: ${({ theme }) => theme.white};
    }

    @media (max-width: 1440px) {
      width: 20px;
      height: 14px;
    }
  }

  @media (max-width: 1080px) {
    width: 27.5px;
    height: 18px;
  }
`;
