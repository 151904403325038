import styled from 'styled-components';

export const SpendingIconContainer = styled.svg`
  width: 1.98vw;
  height: 1.67vw;

  &.report {
    width: 23px;
    height: 18px;
    min-width: 23px;
    min-height: 18px;
  }

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  &.extract.spendings {
    width: 23px;
    height: 18px;
    path {
      fill: ${({ theme }) => theme.blueSky};
    }
    &.selected path {
      fill: ${({ theme }) => theme.white};
    }
    @media (max-width: 1440px){
      width: 20px;
      height: 16px;
    }
  }

  @media (max-width: 1080px) {
    width: 21.4px;
    height: 18px;
  }
`;
