import styled from 'styled-components';

export const PageContainer = styled.div`
  padding-left: 5%;
  padding-right: 32px;
  overflow: auto;
  width: 95vw;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: max-content;
  padding-top: 28.5px;
  padding-bottom: 24px;
  flex-wrap: wrap;
  gap: 12px 0;
  
  @media (max-width: 1600px) {
    padding-top: 18px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.grayWebkitScrollbarThumb};
    border-radius: 5px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.webkitScrollbarTrack};
    height: 2px;
  }
`;
