import styled from "styled-components";

export const Overlay = styled.div`
  &.default {
    z-index: 1000;
    position: relative;
    
    &.top {
        bottom: 300%;
    }
  }
`;

export const CalendarContainer = styled.div`
  &.default {
    width: 192px;
    height: min-content;
    border-radius: 8px;
    padding: 10px;
    background-color: ${props => props.theme.white};
    box-shadow: 0px 4px 15px 0px ${props => props.theme.blackWithSlowOpacity};

    @media (max-width: 1440px) {
        width: 160px;
        padding: 8px;
        box-shadow: 0px 3px 12px 0px ${props => props.theme.blackWithSlowOpacity};
    }

    @media (max-width: 1024px) {
        width: 128px;
        padding: 6.4px;
    }
  }
`;

export const CalendarTitle = styled.div`
  &.default {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.div`
  &.default {
    height: 18px;
    padding-top: 4px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-left: 6px;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 0.5rem;
    line-height: 0.5rem;
    color: ${props => props.theme.grayShadow};

    @media (max-width: 1440px) {
        height: 16px;
        padding-top: 3px;
        padding-right: 8px;
        padding-bottom: 3px;
        padding-left: 4px;
    }

    @media (max-width: 1024px) {
        height: 14px;
        padding-top: 2.4px;
        padding-right: 6.4px;
        padding-bottom: 2.4px;
        padding-left: 4.8px;
        font-size: 0.4rem;
        line-height: 0.4rem;
    }
  }
`;

export const ArrowsContainer = styled.div`
  &.default {
    width: 30px;
    height: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1440px) {
        width: 24px;
        height: 8px;
    }

    @media (max-width: 1024px) {
        width: 20px;
        height: 7px;
    }
  }
`;

export const Day = styled.div`
  &.default {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 0.4rem;
    line-height:0.4rem;
    font-weight: 400;
    color: ${props => props.theme.blueNavy};
    border-radius: 4px;
    cursor: pointer;

    ${props => props.selected === 'currentMonth' && `
        background: ${props.theme.blueRoyal};
        color: ${props.theme.white} !important;
    `}

    ${props => props.type !== 'currentMonth' && `
        color: ${props.theme.graySilver};
    `}

    ${props => props.type === 'title' && `
        color: ${props.theme.white};
        cursor: default;
    `}

    @media (max-width: 1440px) {
        width: 12px;
        height: 12px;
    }

    @media (max-width: 1024px) {
        width: 10px;
        height: 10px;
        font-size: 0.3rem;
        line-height: 0.3rem;
    }
  }
`;

export const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
  margin-top: 12px;
  align-items: center;
  align-items: stretch;
  padding: 0px 2px;

  ${props => props.type === 'title' && `
    background-color: ${props.theme.blueRoyal};
    border-radius: 24px;
    margin-top: 5px;

    @media (max-width: 1440px) {
        border-radius: 16px;
        margin-top: 4px;
    }

    @media (max-width: 1024px) {
        border-radius: 12px;
        margin-top: 3px;
    }
  `}

  @media (max-width: 1440px) {
    gap: 4px;
    margin-top: 8px;
  }

  @media (max-width: 1024px) {
    gap: 3px;
    margin-top: 6px;
  }
`;

export const ButtonContainer = styled.div`
  &.default {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: min-content;
      margin-top: 12px;

      @media (max-width: 1440px) {
          margin-top: 8px;
      }

      @media (max-width: 1024px) {
          margin-top: 6px;
      }
  }
`;