import styled from 'styled-components';

export const EmptyCardRow = styled.div`
  &.summary-card {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-weight: 450;
    letter-spacing: 0em;
    text-align: center;
    color: ${({ theme }) => theme.grayPale};
    min-height: 220px;
  }
`;
