import styled from 'styled-components';

export const StyledLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.detail {
    width: 26.04vw;
  }
`;

export const LegendValue = styled.span`
  font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
  font-size: clamp(1rem, 1.5vw + 0.5rem, larger);
  height: ${props => props.loading && '15px'};
  width: ${props => props.loading && '30px'};

  background: ${props =>
    props.loading
      ? `linear-gradient(90deg, ${props.theme.gradientSkeleton[0]} 25%,
    ${props.theme.gradientSkeleton[1]} 50%,
    ${props.theme.gradientSkeleton[0]} 75%)`
      : 'transparent'};
  background-size: ${props => props.loading && '100% auto'};
  border-radius: ${props => props.loading && '5px'};
  animation: ${props =>
    props.loading ? 'GradientSkeletonAnimation 4s infinite' : 'none'};

  &.color-receipt {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.blueMidnight};
  }
  &.color-spending {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.blueSky};
  }
  &.color-investments {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.greenDark};
  }
  &.color-debts {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.redLight};
  }

  &.detail {
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-size: calc((32 / 1080) * 100vh);
    color: ${({ theme }) => theme.blueRoyal};
  }
`;
export const LegendText = styled.span`
  font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};
  color: transparent;
  height: ${props => props.loading && '15px'};
  width: ${props => props.loading && '50%'};
  background: ${props =>
    props.loading
      ? `linear-gradient(90deg, ${props.theme.gradientSkeleton[0]} 25%,
    ${props.theme.gradientSkeleton[1]} 50%,
    ${props.theme.gradientSkeleton[0]} 75%)`
      : 'transparent'};
  background-size: ${props => props.loading && '200% auto'};
  border-radius: ${props => props.loading && '5px'};
  animation: ${props =>
    props.loading
      ? 'GradientSkeletonAnimation 1.5s ease-in-out infinite'
      : 'none'};

  font-size: medium;
  font-weight: 250;
  &.color {
    color: ${({ theme }) => theme.grayMedium};
  }
  &.detail {
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};
    font-size: calc((18 / 1080) * 100vh);
    color: ${({ theme }) => theme.grayMedium};
  }
`;
