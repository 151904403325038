import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 7px;
  height: 12px;

  &.blue-midnight {
    path {
      stroke: ${({ theme }) => theme.blueMidnight};
    }
  }
`;
