import api from 'services/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { createCategorySchema, createInstallmentsSchema, createItemSchema, updateCategorySchema, updateRecorrenceSchema } from 'utils/validator';
import { format } from 'date-fns';

const useBudgetManagement = () => {
    const { id: userId } = useSelector(state => state.user.profile);

    const updateRecorrence = async (data) => {
        try {
            const url = `/items/recurrence/users/${userId}`;

            const { success, message = null } = await updateRecorrenceSchema(data)
            if (!success) {
                toast.error(message);
                return null
            }

            const res = await api.post(url, {
                item_id: data.id,
                recurrent: data.recurrent
            });
            return res.data
        } catch (error) {
            return null
        }
    }

    const getOverview = async ({ formatedValue = false, types = [], limitCaracters = 0 }) => {
        try {
            const url = `/budget/${userId}/overview`;

            const params = {
                formated_value: formatedValue,
                limit_caracters: limitCaracters
            };

            types.forEach(type => {
                if (['investments', 'spendings', 'receipts', 'debts', 'navigation_card'].includes(type)) {
                    params[type] = true;
                }
            });

            const { data } = await api.get(url, { params });
            return data
        } catch (error) {
            toast.error('Não foi possível carregar os dados da página!');
            return null;
        }
    };

    const createItem = async (data) => {
        const url = `/users/${userId}/categories/${data.categoryId}/items`;

        try {
            const formatedData = await createItemSchema({ ...data, when: data?.maturity })

            if (!formatedData.success) {
                toast.error(formatedData.message);
                return {
                    success: false,
                    message: formatedData.message
                }
            }

            const res = await api.post(url, formatedData.data);

            if (data.recurrent) {
                const updatedRecorrence = await updateRecorrence({ id: res.data.id, recurrent: data.recurrent });

                if (updatedRecorrence === null) {
                    return {
                        success: false,
                        message: "Não foi possível criar o item corretamente!"
                    }
                }
            }

            return {
                success: true,
                data: res.data
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível criar o item!"
            }
        }
    }

    const updateItem = async (data, verifyRecorrence = true) => {
        try {
            const url = `/items/${data?.id}`;

            const res = await api.put(url, data);

            if (verifyRecorrence) {
                const updatedRecorrence = await updateRecorrence({ id: data.id, recurrent: data.recurrent });
                if (updatedRecorrence === null) {
                    return {
                        message: "Não foi possível atualizar o item corretamente!",
                        success: false
                    }
                }
            }

            return {
                success: true,
                data: res.data
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível atualizar o item!"
            }
        }
    }

    const deleteItem = async (itemId) => {
        try {
            const url = `/items/${itemId}`;
            await api.delete(url);

            return {
                success: true,
                message: "Item deletado com sucesso!"
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível deletar o item!"
            }
        }
    }

    const deleteManyItems = async (itemIds) => {
        try {
            const url = `/items/users/${userId}`;
            await api.delete(url, {
                data: {
                    ids: itemIds
                }
            });

            return {
                success: true,
                message: "Itens deletados com sucesso!"
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível deletar os itens!"
            }
        }
    }

    const deleteManyInstallments = async (itemIds) => {
        try {
            const url = `/users/${userId}/installments/delete-many`;

            await api.delete(url, {
                data: {
                    ids: itemIds
                }
            });

            return {
                success: true,
                message: "Itens deletados com sucesso!"
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível deletar os itens!"
            }
        }
    }

    const createCategory = async (data) => {
        try {
            const url = `/users/${userId}/categories`;

            const createdCategory = await createCategorySchema(data);

            if (!createdCategory.success) {
                return {
                    success: false,
                    message: createdCategory.message
                }
            }
            const res = await api.post(url, { ...createdCategory.data });

            return {
                success: true,
                data: res.data
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível criar a categoria!"
            }
        }
    }

    const deleteCategory = async (categoryId) => {
        try {
            const url = `/categories/${categoryId}`;
            await api.delete(url);

            return {
                success: true,
                message: "Categoria deletada com sucesso!"
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível deletar a categoria!"
            }
        }
    }

    const updateCategory = async (data) => {
        try {
            const url = `/categories/${data.id}`;
            const updatedCategory = await updateCategorySchema(data);

            if (!updatedCategory.success) {
                return {
                    success: false,
                    message: updatedCategory.message
                }
            }

            const res = await api.put(url, { ...updatedCategory.data });

            return {
                success: true,
                data: res.data
            }

        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível atualizar a categoria!"
            }
        }
    }

    const deleteInstallments = async (installmentId) => {
        try {
            const url = `/installments/${installmentId}`;
            await api.delete(url);
            return {
                success: true,
                message: "Parcela deletada com sucesso!"
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível deletar a parcela!"
            }
        }
    }

    const getCategoriesWithItems = async (data) => {
        try {
            const url = `/users/${userId}/categories`;
            const {
                items = true,
                type = 'spendings'
            } = data

            const res = await api.get(url, {
                params: {
                    items,
                    type
                }
            });
            return res.data
        } catch (error) {
            return null
        }
    }

    const createInstallment = async (data) => {
        try {
            await createInstallmentsSchema.validate(data)
            const url = `/users/${userId}/installments`;
            const res = await api.post(url, data);
            return {
                success: true,
                data: res.data
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível criar a parcela!"
            }
        }
    }

    const updateInstallment = async (data) => {
        try {
            await createInstallmentsSchema.validate(data)
            const url = `/installments/${data.id}`;
            const res = await api.put(url, data);

            return {
                success: true,
                data: res.data
            }
        } catch (error) {
            console.error(error.message);
            return {
                success: false,
                message: "Não foi possível atualizar a parcela!"
            }
        }
    }

    const getExpenseOverview = async ({
        start,
        end,
        types = [],
        formatedValue = true,
        limitCaracters = 10
    }) => {
        const url = `/budget/expense_overview/${userId}`

        try {
            const params = {
                formated_value: formatedValue,
                limit_caracters: limitCaracters,
                start: format(start, 'yyyy-MM-dd'),
                end: format(end, 'yyyy-MM-dd'),
            };

            types.forEach(type => {
                if (['flexible_monthly', 'flexible_eventual', 'monthly_committed', 'eventual_committed', 'navigation_card'].includes(type)) {
                    params[type] = true;
                }
            });

            const res = await api.get(url, { params });

            return res.data
        } catch (error) {
            console.error(error.message);
            toast.error("Não foi possível buscar os dados da página");
            return null
        }
    }

    const updatePec = async (data) => {
        try {
            const { id, value } = data

            if (!id) {
                await api.post(`/users/${userId}/pec`, { value })
            } else {
                await api.put(`/users/${userId}/pec/${id}`, { value })
            }

            return data
        } catch (error) {
            console.error(error.message);
            return null
        }
    }

    return {
        getOverview,
        createItem,
        updateItem,
        deleteItem,
        createCategory,
        deleteCategory,
        updateCategory,
        deleteManyItems,
        deleteInstallments,
        deleteManyInstallments,
        getCategoriesWithItems,
        createInstallment,
        updateInstallment,
        getExpenseOverview,
        updateRecorrence,
        updatePec
    };
};

export default useBudgetManagement;