import styled from 'styled-components';

export const StyledIcon = styled.svg`
  margin-right: 20px;
  width: 30px;
  height: 30px;
  min-width: 20px;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`;
