import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: hidden;

  &.loading {
    display: none;
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.blueMidnight};
  color: ${({ theme }) => theme.blueMidnight};
  height: 38px;
  padding: 5px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
  font-weight: 600;

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  &.month {
    width: 250px;
    min-width: 150px;
  }
  &.year {
    width: 116px;
    min-width: 80px;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 100%;
  cursor: pointer;
`;